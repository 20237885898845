export const updateCheckboxPropertyList = ({
  filterChecked,
  propertyList,
  target,
  checked, // onChange 일어난 이후의 체크상태,
  allPropertyList,
}) => {
  const allChecked = filterChecked.every(checked => checked);
  const noneChecked = filterChecked.every(checked => !checked);

  if (allChecked) {
    const updatedList = propertyList.filter(
      membership => membership !== target
    );

    return updatedList;
  }

  if (noneChecked) {
    return [target];
  }

  if (checked) return [...new Set(propertyList.concat(target))];

  const updatedList = propertyList.filter(property => property !== target);

  if (updatedList.length === 0) {
    return allPropertyList;
  }

  return updatedList;
};
