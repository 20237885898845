import React, { useEffect, useState, useCallback, Suspense, lazy } from 'react';
import { Box, Flex, Select, Input, Skeleton } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import { ResetBtn } from 'styles/ComponentStyle';
import { Pagination } from 'Common/Pagination';
import { useSearchParams } from 'react-router-dom';
import { getInquiry } from 'api/auth/inquiry';
import UseResponseToast from 'Hook/UseResponseToast';
const QuestionTable = lazy(() => import('./QuestionTable'));

const SearchBtn = styled.button`
  background-color: #b8c6db;
  border: 1px solid #b8c6db;
  border-radius: 5px;
  padding: 8px 15px;
  margin-left: 10px;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
`;

const Questions = () => {
  const responseToast = UseResponseToast();
  const [loading, setLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page')) || 1;
  const [category, setCategory] = useState('이용 문의');
  const [searchContent, setSearchContent] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [listAll, setListAll] = useState('');
  const [maxPage, setMaxPage] = useState(0);

  const offset = (currentPage - 1) * 30;

  const SearchQuestion = useCallback(
    async e => {
      setLoading(true);
      const { response, title, description, statusCode, error } =
        await getInquiry({
          page: currentPage,
          count: 50,
          category: category,
          content: searchContent,
          search: searchKeyword,
        });
      setLoading(false);
      responseToast({ statusCode, title, description });

      if (error) {
        return;
      }
      setListAll(response.data.data);
      setMaxPage(response.data.config.maxPage);
    },
    [category, searchContent, searchKeyword, currentPage, responseToast]
  );

  const HandleCategory = e => {
    setCategory(e.target.value);
  };

  const HandleSerachContent = e => {
    setSearchContent(e.target.value);
  };

  const HandleSearchKeyword = e => {
    setSearchKeyword(e.target.value);
  };

  const ResetData = () => {
    setCategory('이용 문의');
    setSearchContent('');
    setSearchKeyword('');
    fetchData();
  };

  const fetchData = useCallback(async () => {
    const { response, title, description, statusCode, error } =
      await getInquiry({
        page: currentPage,
        count: 50,
      });

    responseToast({ title, description, statusCode });

    if (error) {
      return;
    }

    setMaxPage(response.data.config.maxPage);
    const data = response.data.data;

    const orderData = data.sort((a, b) => b.inquiry_uid - a.inquiry_uid);

    setListAll(orderData);
  }, [currentPage, responseToast]);

  useEffect(() => {
    fetchData(); // 데이터 요청 로직
  }, [currentPage]);

  return (
    <>
      <Box className="MemberContainer">
        <Box
          maxW="1300px"
          m="0 auto"
          bg="#fff"
          padding="36px"
          textAlign="center"
          boxShadow="rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"
        >
          <Flex maxW="600px">
            <Select defaultValue={'이용 문의'} onChange={HandleCategory}>
              <option value="이용 문의">이용 문의</option>
              <option value="오류 신고">오류 신고</option>
              <option value="서비스 제안">서비스 제안</option>
              <option value="환불">환불</option>
              <option value="탈퇴">탈퇴</option>
              <option value="기타">기타</option>
            </Select>
            <ResetBtn onClick={ResetData}>전체보기</ResetBtn>
          </Flex>
          <form>
            <Flex
              direction={{ base: 'column', sm: 'row' }}
              gridGap={'10px'}
              mt="10px"
            >
              <Input
                w="100%"
                maxW="500px"
                placeholder="문의 내용을 적어주세요."
                value={searchContent}
                onChange={HandleSerachContent}
              />
              <Flex
                direction={{ base: 'column', sm: 'row' }}
                justify="space-between"
                align="center"
                w="100%"
                maxW="500px"
              >
                <Input
                  placeholder="회원명 및 이메일 주소를 정확하게 적어주세요."
                  value={searchKeyword}
                  onChange={HandleSearchKeyword}
                />
                <SearchBtn
                  type="submit"
                  onClick={e => {
                    e.preventDefault();
                    SearchQuestion();
                  }}
                >
                  <SearchIcon />
                </SearchBtn>
              </Flex>
            </Flex>
          </form>
        </Box>
      </Box>
      <Box className="TableContainer">
        <Box
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': {
              //스크롤바 전체영역
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              //스크롤바 움직이는 영역
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              //스크롤
              backgroundColor: '#E6F4F1',
              borderRadius: '5px',
            },
          }}
        >
          <Suspense>
            <Skeleton h="100%" isLoaded={!loading}>
              <QuestionTable offset={offset} listAll={listAll} />
            </Skeleton>
          </Suspense>
        </Box>
        <Pagination maxPage={maxPage} currentPage={currentPage} />
      </Box>
    </>
  );
};

export default Questions;
