import React from 'react';
import { StatBox } from 'styles/ComponentStyle';
import {
  Box,
  Heading,
  StatLabel,
  StatNumber,
  StatHelpText,
  Flex,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { DashBoard } from './DashBoardChart';
import { FcOpenedFolder } from 'react-icons/fc';
import { useRecoilState } from 'recoil';
import { userStatisticsState } from 'config/Recoil';

const BoardBox = styled(Box)`
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 4px 4px 17px -5px rgb(0 0 0 / 20%);
  margin-top: 30px;
`;

const Home = () => {
  const [userStatistics] = useRecoilState(userStatisticsState);

  const { signup, currentSum, currentOne, currentThree, currentSix } =
    userStatistics;

  const current_data = [
    {
      name: '1개월',
      회원수: parseInt(currentOne),
    },
    {
      name: '3개월',
      회원수: parseInt(currentThree),
    },
    {
      name: '6개월',
      회원수: parseInt(currentSix),
    },
  ];

  return (
    <Box maxW="1400px" m="0 auto">
      <Box p={{ base: '3rem 2rem', lg: '4rem 2rem' }}>
        <Flex
          gap="20px"
          align="center"
          padding={{ base: '30px 20px', sm: '30px' }}
        >
          <FcOpenedFolder size={'3em'} />
          <Heading as="h2" size="xl" color="#43655A">
            DashBoard
          </Heading>
        </Flex>
        <BoardBox p={{ base: '2rem', md: '3rem' }}>
          <Heading as="h3" size="lg" fontWeight="600" mb="40px" color="#43655A">
            Sign_up
          </Heading>
          <Flex gap="15px" direction={{ base: 'column', md: 'row' }}>
            <StatBox>
              <StatLabel>누적 가입회원</StatLabel>
              <StatNumber>{signup.accumulate} 명</StatNumber>
              <StatHelpText>현재까지 가입한 라이팅젤 회원</StatHelpText>
            </StatBox>
            <StatBox>
              <StatLabel>당일 가입회원</StatLabel>
              <StatNumber>{signup.daily} 명</StatNumber>
              <StatHelpText>당일 가입한 라이팅젤 회원</StatHelpText>
            </StatBox>
          </Flex>
        </BoardBox>

        <BoardBox p={{ base: '2rem', md: '3rem' }}>
          <Heading as="h3" size="lg" fontWeight="600" mb="40px" color="#43655A">
            구독(현재)
          </Heading>
          <DashBoard
            data={current_data}
            sum={currentSum}
            fillColor={'#ACA7CB'}
            statTitle={'총 현재 구독회원'}
            statText={'현재 구독 회원'}
          />
        </BoardBox>
      </Box>
    </Box>
  );
};

export default Home;
