import getAuthAxios from 'utill/getAuthAxios';

const authAxios = getAuthAxios();

export const createPrompt = ({
  name,
  text,
  max_tokens,
  temperature,
  frequency_penalty,
  presence_penalty,
  stop_sequence,
  model,
  is_aivs,
  is_get_image,
}) => {
  return authAxios.post(`/prompt`, {
    name,
    text,
    max_tokens,
    temperature,
    frequency_penalty,
    presence_penalty,
    stop_sequence,
    model, // "naver"를 값으로 넣으면 clova로 작동, claude3 추가
    is_aivs, // aivs에 사용할지 여부.
    is_get_image, // aivs에서 이미지를 받는지 여부.
  });
};

// '&orderorder_by[0][key]=name&order_by[0][order]=ASC'
export const getPromptList = ({ page, count, order_by }) => {
  return authAxios.get(
    `/prompt/list?page=${page}&count=${count}&order_by=${order_by}`
  );
};

export const uploadPrompt = ({
  promptUid,
  name,
  text,
  max_tokens,
  temperature,
  frequency_penalty,
  presence_penalty,
  stop_sequence,
  model,
  is_aivs,
  is_get_image,
}) => {
  return authAxios.put(`/prompt/${promptUid}`, {
    name,
    text,
    max_tokens,
    temperature,
    frequency_penalty,
    presence_penalty,
    stop_sequence,
    model,
    is_aivs,
    is_get_image,
  });
};

export const deletePrompt = promptUid => {
  return authAxios.delete(`/prompt/${promptUid}`);
};

export const getPrompt = promptUid => {
  return authAxios.get(`/prompt/${promptUid}`);
};
