import { useEffect, useState } from 'react';
import * as server from 'config/Config';
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Select,
  useToast,
} from '@chakra-ui/react';
import { ModifyBtn, SmallDelete } from 'styles/ComponentStyle';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { deleteCoupon, getCouponDetail, updateCoupone } from 'api/auth/coupon';
import UseResponseToast from 'Hook/UseResponseToast';

const SerialDetailBox = styled(Flex)`
  align-items: center;
  padding: 15px 15px 12px 15px;
  border-bottom: 1px solid #ededed;

  > h4 {
    min-width: 100px;
    font-weight: 600;
  }

  > input {
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    width: 100%;
    padding: 7px 8px 7px 15px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    padding: 15px 5px;

    > h4,
    p {
      width: 100%;
    }
  }
`;

const SerialDetail = ({ UID, admin, isOpen, onClose }) => {
  const toast = useToast();
  const responseToast = UseResponseToast();
  const [details, setDetails] = useState({
    coupon_uid: '',
    campaign_name: '',
    desc: '',
    plan: '',
    is_used: '',
    used_user_id: '',
    create_at: '',
    used_at: '',
    user_email: '',
  });
  const [endDate, setEndDate] = useState('');
  const {
    coupon_uid,
    campaign_name,
    desc,
    plan,
    is_used,
    user_email,
    create_at,
    used_at,
  } = details;

  const [previous, setPrevious] = useState({
    PreCampaign_name: '',
    PreDesc: '',
    PrePlan: '',
  });

  const { PreCampaign_name, PreDesc, PrePlan } = previous;

  const InputChange = e => {
    setDetails({
      ...details,
      [e.target.id]: e.target.value,
    });
  };

  const ModifyDetail = async () => {
    if (
      PreCampaign_name === campaign_name &&
      PreDesc === desc &&
      PrePlan === plan
    ) {
      toast({
        title: '내용이 그대로 입니다!',
        description: `수정된 내용이 없습니다.`,
        position: 'top-right',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const { response, title, description, statusCode, error } =
      await updateCoupone({
        coupon_uid: UID,
        campaign_name,
        desc,
        plan,
      });

    responseToast({ title, description, statusCode });

    if (error) {
      return;
    }
  };

  const DeleteDetail = async () => {
    const { response, title, description, statusCode, error } =
      await deleteCoupon(UID);

    responseToast({
      title,
      description,
      statusCode,
    });

    if (error) {
      return;
    }
    onClose();
  };

  useEffect(() => {
    const fetchCouponDetail = async () => {
      if (UID) {
        const { response, title, description, statusCode, error } =
          await getCouponDetail(UID);

        responseToast({ title, description, statusCode });

        if (error) {
          return;
        }

        const data = response.data.data;

        setDetails({
          ...details,
          coupon_uid: data.coupon_uid,
          campaign_name: data.campaign_name,
          desc: data.desc,
          plan: data.plan,
          is_used: data.is_used,
          used_user_id: data.used_user_id,
          create_at: data.create_at,
          used_at: data.used_at,
          user_email: data.user_email,
        });

        let after = dayjs(data.used_at).add(data.plan, 'month');
        let afterDay = dayjs(after.$d).format('YYYY-MM-DD');
        setEndDate(afterDay);

        setPrevious({
          ...previous,
          PreCampaign_name: data.campaign_name,
          PreDesc: data.desc,
          PrePlan: data.plan,
        });
      }
    };
    fetchCouponDetail();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Box>
            <SerialDetailBox>
              <h4>쿠폰코드</h4>
              <p>{coupon_uid}</p>
            </SerialDetailBox>
            <SerialDetailBox>
              <h4>캠페인명</h4>
              <input
                type="text"
                id="campaign_name"
                value={campaign_name}
                onChange={InputChange}
              />
            </SerialDetailBox>
            <SerialDetailBox>
              <h4>상세설명</h4>
              <input
                type="text"
                id="desc"
                value={desc}
                onChange={InputChange}
              />
            </SerialDetailBox>
            <SerialDetailBox>
              <h4>생성일자</h4>
              <p>{dayjs(create_at).format('YYYY-MM-DD')}</p>
            </SerialDetailBox>
            <SerialDetailBox>
              <h4>혜택구분</h4>
              <Select
                className="selectOption"
                id="plan"
                value={plan}
                onChange={e =>
                  setDetails({ ...details, plan: Number(e.target.value) })
                }
              >
                <option value={0} disabled>
                  혜택을 선택해주세요
                </option>

                <option value={1}>1개월</option>
                <option value={3}>3개월</option>
                <option value={6}>6개월</option>
              </Select>
            </SerialDetailBox>
            <SerialDetailBox>
              <h4>사용여부</h4>
              <p>{is_used ? '사용' : '미사용'}</p>
            </SerialDetailBox>
            <SerialDetailBox>
              <h4>사용기간</h4>
              <p>
                {used_at
                  ? `${dayjs(used_at).format('YYYY-MM-DD')} ~ ${endDate}`
                  : '아직 사용되지 않았습니다.'}
              </p>
            </SerialDetailBox>
            <SerialDetailBox>
              <h4>사용자</h4>
              <p>{user_email ? user_email : '사용자 아이디가 없습니다.'}</p>
            </SerialDetailBox>
          </Box>
        </ModalBody>
        <ModalFooter justifyContent={'center'}>
          <HStack>
            <ModifyBtn onClick={ModifyDetail}>수정</ModifyBtn>
            <SmallDelete onClick={DeleteDetail}>삭제</SmallDelete>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SerialDetail;
