import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Checkbox,
  Flex,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { Suspense } from 'react';
import MemberTable from './MemberTable';
import { Pagination } from 'Common/Pagination';
import IsDeleteModal from 'Common/IsDeleteModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UseResponseToast from 'Hook/UseResponseToast';
import { deleteUser } from 'api/auth/user';
import { FaChevronDown } from 'react-icons/fa';
import MemberTableHead from './MemberTableHead';

const TableContainerBox = ({
  onOpen,
  onClose,
  isOpen,
  setCheckedItems,
  checkedItems,
  loading,
  idList,
  searchList,
  maxPage,
  membersOrderBy,
  setMembersOrderBy,
}) => {
  const toast = useToast();
  const responseToast = UseResponseToast();
  const [searchParams] = useSearchParams();

  const currentPage = parseInt(searchParams.get('page')) || 1;
  const offset = (currentPage - 1) * 30;
  const navigate = useNavigate();

  const checkEach = (e, id) => {
    if (e.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter(item => item !== id));
    }
  };

  const deleteUsers = async () => {
    const checkedArray = idList.filter(item => checkedItems.includes(item));

    if (checkedArray.length === 0) {
      onClose();
      toast({
        title: '선택한 유저가 없어요!',
        description: '삭제할 유저를 선택해주세요.',
        position: 'top-right',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    }

    if (checkedArray.length <= 1) {
      const { statusCode, response, title, description, error } =
        await deleteUser(checkedArray[0]);

      responseToast({ statusCode, title, description });

      if (error) {
        return;
      }

      navigate(0);
      return;
    }

    Promise.all(
      checkedArray.map(async param => {
        const { statusCode, response, title, description, error } =
          await deleteUser(checkedArray[0]);
        return { response, title, description, statusCode };
      })
    )
      .then(({ statusCode, title, description }) => {
        responseToast({ statusCode, title, description });
        navigate(0);
      })
      .catch(error => {
        responseToast({
          title: '유저 삭제 실패',
          description: '유저 삭제를 실패했습니다.',
        });
      });
  };

  return (
    <Box className="TableContainer">
      <Flex justify="flex-end" mb={25} spacing="15px">
        <DeleteIcon
          onClick={onOpen}
          w={5}
          h={5}
          style={{ cursor: 'pointer' }}
        />
      </Flex>
      <Box
        overflowX="auto"
        css={{
          '&::-webkit-scrollbar': {
            //스크롤바 전체영역
            width: '5px',
          },
          '&::-webkit-scrollbar-track': {
            //스크롤바 움직이는 영역
            backgroundColor: '#fff',
          },
          '&::-webkit-scrollbar-thumb': {
            //스크롤
            backgroundColor: '#E6F4F1',
            borderRadius: '5px',
          },
        }}
      >
        <Suspense>
          <Skeleton h="100%" isLoaded={!loading}>
            <table className="MemberCustomTableStyle">
              <MemberTableHead
                membersOrderBy={membersOrderBy}
                setMembersOrderBy={setMembersOrderBy}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                idList={idList}
              />

              <MemberTable
                offset={offset}
                searchList={searchList}
                checkedItems={checkedItems}
                CheckEach={checkEach}
              />
            </table>
          </Skeleton>
        </Suspense>
      </Box>
      <Pagination maxPage={maxPage} currentPage={currentPage} toast={toast} />
      <IsDeleteModal isOpen={isOpen} onClose={onClose} Delete={deleteUsers} />
    </Box>
  );
};

export default TableContainerBox;
