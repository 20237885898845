import axios from 'axios';
import { SERVER_URL } from 'config/Config';

axios.defaults.baseURL = SERVER_URL;

export const adminLogin = ({ id, password }) => {
  return axios.post(`/login`, {
    id,
    password,
  });
};
