import { getUserList } from 'api/auth/user';

export async function requestUserList({
  body,
  setLoading,
  setSearchList,
  setIdList,
  setMaxPage,
}) {
  setLoading(true);
  const { response, title, description } = await getUserList(body);
  setLoading(false);

  const data = response.data.data;
  const orderList = data.sort(
    (a, b) => new Date(b.user.create_at) - new Date(a.user.create_at)
  );
  setSearchList(orderList);

  let idList = [];
  const ids = orderList.map((item, i) => (idList[i] = item.user.user_uid));
  setIdList(ids);
  setMaxPage(response.data.config.maxPage);
}
