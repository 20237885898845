import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
  useToast,
} from '@chakra-ui/react';
import { updateUserToken } from 'api/auth/plan';
import UseResponseToast from 'Hook/UseResponseToast';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const TokenInputModal = ({
  tokenModalInfo,
  setTokenInputModalInfo,
  tokenInput,
  setTokenInput,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const responseToast = UseResponseToast();
  const toast = useToast();

  const onCloseTokenModal = () => {
    setTokenInputModalInfo({
      open: false,
      target: '',
    });
  };

  const HandleChangeToken = async (token, tokenType) => {
    if (!token) {
      toast({
        title: '적용할 숫자가 없습니다.',
        description: '숫자를 적어주세요!',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { statusCode, response, title, description, error } =
      await updateUserToken({ user_uid: id, token, tokenType });

    responseToast({
      statusCode,
      title,
      description,
    });

    if (error) {
      return;
    }

    navigate(0);
  };

  return (
    <Modal isOpen={tokenModalInfo.open} onClose={onCloseTokenModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{tokenModalInfo.target} 토큰 적용</ModalHeader>
        <ModalCloseButton onClick={onCloseTokenModal} />
        <ModalBody>
          <Text fontSize={'md'} mb="10px">
            📌 적용할 토큰 숫자를 적어주세요!
          </Text>
          <NumberInput
            value={tokenInput}
            onChange={value => setTokenInput(parseInt(value))}
          >
            <NumberInputField />
          </NumberInput>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme={'purple'}
            onClick={() => HandleChangeToken(tokenInput, tokenModalInfo.target)}
          >
            적용하기
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TokenInputModal;
