import styled from 'styled-components';
import { Flex, Stat, Checkbox } from '@chakra-ui/react';

export const Back = styled.button`
  background-color: #b8c6db;
  border: 1px solid #b8c6db;
  padding: 2px 10px;
  border-radius: 5px;
  margin-right: 10px;

  transition: all 300ms ease;

  &:hover {
    background-color: #e2e8f0;
    border: 1px solid #e2e8f0;
  }
`;

export const DeleteCancelBtn = styled.button`
  background-color: ${props => (props.cancel ? '#e2e8f0' : '#ff5a52')};
  border-radius: 5px;
  color: ${props => (props.cancel ? '#474554' : '#fff')};
  padding: 2px 25px;
  transition: all 300ms ease;

  &:hover {
    background-color: ${props => (props.cancel ? '#b8c6db' : '#d83536')};
  }
`;
export const SmallDelete = styled.button`
  background-color: #ff5a52;
  padding: 2px 8px;
  border: 1px solid #ff5a52;
  border-radius: 5px;
  color: #fff;
  width: 80px;
  transition: all 300ms ease;

  &:hover {
    background-color: #d83536;
    border: 1px solid #d83536;
  }
`;

export const ModifyBtn = styled.button`
  background-color: #43655a;
  border: 1px solid #43655a;
  border-radius: 5px;
  color: #fff;
  padding: 2px 25px;
  transition: all 300ms ease;

  &:hover {
    background-color: #618180;
    border: 1px solid #618180;
  }
`;

export const ExcelDownBtn = styled.button`
  background-color: #43655a;
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 15px;
  transition: all 300ms ease;
  word-break: keep-all;

  &:hover {
    background-color: #618180;
  }
`;

export const ResetBtn = styled.button`
  background-color: #e2e8f0;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  color: #474554;
  padding: 2px 25px;
  font-size: 15px;
  margin: 0 8px;
  transition: all 300ms ease;
  word-break: keep-all;

  &:hover {
    background-color: #b8c6db;
    border: 1px solid #b8c6db;
  }
`;

export const BtnBox = styled.div`
  width: 100%;
  text-align: center;
  padding: 30px 0;
`;

export const SerialInputBox = styled(Flex)`
  align-items: center;
  margin-bottom: 20px;

  > label {
    min-width: 110px;
    font-weight: 600;
    word-break: keep-all;
    margin-bottom: 5px;
  }
`;

export const TrStyle = styled.tr`
  text-align: center;
`;

export const TbodyStyle = styled.tbody`
  > tr > td {
    padding: 5px 8px;
  }
`;

export const ExtraBtn = styled.button`
  background-color: #37a169;

  color: #fff;
  border-radius: 5px;
  padding: 2px 10px;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: #318e5d;
  }
`;
export const StatBox = styled(Stat)`
  border: 1px solid #b8c6db;
  border-radius: 10px;
  padding: 15px 15px 10px;
  max-width: 500px;
`;
export const LogOut = styled.button`
  background-color: #ff5a52;
  width: 100%;
  border-radius: 5px;
  color: #fff;
  padding: 5px 13px;
  transition: all 300ms ease;

  &:hover {
    background-color: #d83536;
    font-weight: 600;
  }
`;

export const PayMethodBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;

  padding: 0px 5px;
  border-radius: 5px;
  background-color: ${props => props.bg};
  border: 1px solid ${props => props.borderColor};
  color: ${props => props.font};
  transition: all 300ms ease-in-out;
  font-weight: 600;
  font-size: 15px;

  &:hover {
    background-color: ${props => props.hoverBg};
  }
`;

export const PayMethodCheckbox = styled(Checkbox)`
  padding: 1px 5px;
  border-radius: 5px;
  background-color: ${props => props.bg};
  border: 1px solid ${props => props.hoverbg};
  color: ${props => props.font};
  transition: all 300ms ease-in-out;
  font-weight: 600;

  &:hover {
    background-color: ${props => props.hoverbg};
  }
`;

export const InfoDetailBox = styled(Flex)`
  padding: 15px;
  gap: 10px;

  > h4 {
    min-width: 100px;
    font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
`;

export const QuestionBox = styled(Flex)`
  background-color: #fff;
  padding: 15px;
  gap: 50px;
  border-bottom: 1px solid #ededed;

  > h4 {
    font-weight: 600;
    min-width: 60px;
  }

  > p {
    max-width: 800px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
`;
