import { Button, Flex, Select, Text, useToast } from '@chakra-ui/react';
import { updateUserAutogptPlan } from 'api/auth/plan';
import UseResponseToast from 'Hook/UseResponseToast';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InfoDetailBox } from 'styles/ComponentStyle';

const UserAutoGPTInfo = ({ initialAuto }) => {
  const [autoPlan, setAutoPlan] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const responseToast = UseResponseToast();

  const handleAutoPlan = async () => {
    let plan = parseInt(autoPlan);
    if (!autoPlan) {
      toast({
        title: '선택한 플랜이 없습니다.',
        description: '플랜을 선택해주세요!',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { statusCode, response, title, description, error } =
      await updateUserAutogptPlan({ user_uid: id, plan });
    responseToast({
      statusCode,
      title,
      description,
    });
    if (error) {
      return;
    }
    navigate(0);
  };

  return (
    <InfoDetailBox>
      <h4>AUTO-GPT</h4>
      <Flex direction={'column'} gap="10px">
        <Text fontWeight={700}>
          {initialAuto?.curentPlan
            ? initialAuto?.curentPlan?.name
            : initialAuto
            ? initialAuto
            : '불러오는 중입니다'}
        </Text>
        <Flex direction={{ base: 'column', md: 'row' }} align={'center'}>
          <Select
            placeholder="📌 수정할 플랜을 선택해주세요!"
            onChange={e => setAutoPlan(e.target.value)}
          >
            <option value="1">FREE</option>
            <option value="2">BASIC</option>
            <option value="3">PREMIUM</option>
          </Select>
          <Button
            size={'sm'}
            colorScheme={'green'}
            w={{ base: '100%', md: '105px' }}
            ml={{ base: '0px', md: '10px' }}
            mt={{ base: '10px', md: '0' }}
            onClick={handleAutoPlan}
          >
            적용
          </Button>
        </Flex>
      </Flex>
    </InfoDetailBox>
  );
};

export default UserAutoGPTInfo;
