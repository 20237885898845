import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Home from 'Components/Home.jsx';
import Members from 'Components/Management/Member/Members.jsx';
import Questions from 'Components/Management/Questions/index.jsx';
import MemInfo from 'Components/Management/Member/MemInfo.jsx';
import Landing from 'Components/Landing/index.jsx';
import MemPay from 'Components/Management/Member/MemPay.jsx';
import QuestionDetail from 'Components/Management/Questions/QuestionDetail.jsx';
import Prompts from 'Components/Management/Prompt/Prompts';
import PromptDetail from 'Components/Management/Prompt/PromptDetail.jsx';
import CreatePropmt from 'Components/Management/Prompt/CreatePrompt';
import NotFound from 'Common/NotFound';
import CreateSerial from 'Components/Management/Serials/CreateSerial';
import GetSerial from 'Components/Management/Serials/GetSerial';
import Layout from 'Common/Layout';
// import ChatPrompts from 'Components/ChatPrompts';
// import CreateChatPropmt from 'Components/ChatPrompts/CreateChatPrompts';
// import ChatPromptDetail from 'Components/ChatPrompts/ChatPromptDetail';
import MakeSerial from 'Components/AutoGPT/MakeAutoSerial';
import GetAutoerial from 'Components/AutoGPT/GetAutoSerial';
import GroupsList from 'Components/Management/GroupsList/GroupsList';
import GroupDetail from 'Components/Management/GroupsList/GroupDetail';
import { useRecoilState } from 'recoil';
import { userStatisticsState } from 'config/Recoil';
import { getUserDefault } from 'api/auth/user';
import UseResponseToast from 'Hook/UseResponseToast';

function Apps() {
  const navigate = useNavigate();

  const [userStatistics, setUserStatistics] =
    useRecoilState(userStatisticsState);

  const location = useLocation();

  useEffect(() => {
    const fetchUserDefault = async () => {
      try {
        const { response } = await getUserDefault();

        const data = response.data.data;

        const subscribeCurrent = data.subscribe.current;

        const ArraryCurValues = Object.values(subscribeCurrent);

        const CurValueSum =
          parseInt(ArraryCurValues[0]) +
          parseInt(ArraryCurValues[1]) +
          parseInt(ArraryCurValues[2]);

        setUserStatistics({
          signup: data.signup,
          currentSum: CurValueSum,
          currentOne: ArraryCurValues[0],
          currentThree: ArraryCurValues[1],
          currentSix: ArraryCurValues[2],
        });
      } catch (error) {
        navigate('/login');
      }
    };

    fetchUserDefault();
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="members" element={<Members />}></Route>
        <Route path="members/:id" element={<MemInfo />}></Route>
        <Route path="members/:id/payment" element={<MemPay />}></Route>

        <Route path="questions/" element={<Questions />}></Route>
        <Route path="questions/:id" element={<QuestionDetail />}></Route>
        <Route path="prompts" element={<Prompts />}></Route>
        <Route path="prompts/create" element={<CreatePropmt />} />
        <Route path="prompts/:id" element={<PromptDetail />} />

        {/* <Route path="chat_prompts" element={<ChatPrompts />} />
          <Route path="chat_prompts/create" element={<CreateChatPropmt />} />
          <Route path="chat_prompts/:id" element={<ChatPromptDetail />} /> */}

        <Route path="createSerial" element={<CreateSerial />} />
        <Route path="getSerial" element={<GetSerial />} />
        <Route path="autogpt/createSerial" element={<MakeSerial />} />
        <Route path="autogpt/getSerial" element={<GetAutoerial />} />

        <Route path="/group" element={<GroupsList />} />
        <Route path="/group/:uid" element={<GroupDetail />} />
      </Route>

      <Route path="/login" element={<Landing />} />

      <Route path="*" element={<NotFound />} />
      {/* 404 */}
    </Routes>
  );
}

export default Apps;
