import React, { useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  useToast,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { adminState } from 'config/Recoil';
import { useSetRecoilState } from 'recoil';
import { adminLogin } from 'api/login';
import UseResponseToast from 'Hook/UseResponseToast';
import { getUserDefault } from 'api/auth/user';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const responseToast = UseResponseToast();

  const setAdminState = useSetRecoilState(adminState);

  const [Inputs, SetInputs] = useState({
    userId: '',
    userPassword: '',
  });

  const { userId, userPassword } = Inputs;

  const ChangeInput = e => {
    SetInputs({ ...Inputs, [e.target.name]: e.target.value });
  };

  const GoLogin = async e => {
    try {
      e.preventDefault();

      const res = await adminLogin({ id: userId, password: userPassword });
      const token = res.data.data.token;
      localStorage.setItem('admin_token', res.data.data.token);

      const { statusCode, response, title, description, error } =
        await getUserDefault();

      responseToast({
        statusCode,
        title,
        description,
      });

      if (error) {
        return;
      }

      const data = response.data.data;

      setAdminState({
        ...adminState,
        token: token,
        id: data.id,
        name: data.name,
        admin_uid: data.admin_uid,
        is_root: data.is_root,
        login_at: data.login_at,
        create_at: data.create_at,
        update_at: data.update_at,
        delete_at: data.delete_at,
      });

      navigate('/');
    } catch (error) {
      toast({
        title: '로그인 실패!',
        description: '혹시 빈 칸이 있나요? 혹은 가입하지 않은 유저신가요?',
        position: 'top',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <LandingBox
      minH="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <FormBox bg="#fff" borderRadius="15px">
        <Flex
          direction={'column'}
          align="center"
          justify={'center'}
          marginBottom="30px"
        >
          <Img src="/logo2.png" alt="로고" />
        </Flex>
        <form>
          <Box>
            <FormControl id="email" style={{ marginBottom: '10px' }}>
              <FormLabel>ADMIN EMAIL</FormLabel>
              <Input type="email" name="userId" onChange={ChangeInput} />
            </FormControl>
            <FormControl id="password">
              <FormLabel>ADMIN PASSWORD</FormLabel>
              <Input
                type="password"
                name="userPassword"
                onChange={ChangeInput}
              />
              <FormHelperText>
                We'll never share your email and password.
              </FormHelperText>
            </FormControl>
          </Box>
          <Box textAlign="center" marginTop="30px">
            <LoginBtn type="submit" onClick={GoLogin}>
              로그인
            </LoginBtn>
          </Box>
        </form>
      </FormBox>
    </LandingBox>
  );
};

export default Landing;

const LandingBox = styled(Box)`
  background-color: #b1b5e6;
  background-image: linear-gradient(315deg, #b1b5e6 0%, #f5f7fa 74%);

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
`;

const FormBox = styled(Box)`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  padding: 48px;

  @media screen and (max-width: 768px) {
    padding: 48px 24px;
  }
`;

const LoginBtn = styled.button`
  background-color: #d5d8f0;
  border: 1px solid #d5d8f0;

  border-radius: 10px;
  color: #474554;
  font-weight: 600;
  padding: 5px 8px;
  width: 100%;

  transition: all 300ms ease;

  &:hover {
    background-color: #b1b5e6;
    border: 1px solid #b1b5e6;
  }
`;

const Img = styled.img`
  width: 150px;
`;
