import React from 'react';
import { Flex, Checkbox } from '@chakra-ui/react';
import { updateCheckboxPropertyList } from 'utill/updateCheckboxPropertyList';

const MemberCheckbox = ({
  membershipFilterChecked,
  setMembershipFilterChecked,
  setMembershipList,
  membershipList,
}) => {
  const allChecked = membershipFilterChecked.every(checked => checked);

  const checkAll = () => {
    // 전체 체크
    if (allChecked) {
      // navigate(`?`);
      setMembershipFilterChecked([false, false, false, false, false]);
    } else {
      setMembershipFilterChecked([true, true, true, true, true]);
    }
    setMembershipList([0, 1, 3, 6]);
  };

  // 0 1 3 6
  return (
    <Flex direction={{ base: 'column', sm: 'row' }} className="MemberCheck">
      <Checkbox
        name="all"
        value="0,1,3,6"
        colorScheme="blue"
        isChecked={allChecked}
        onChange={checkAll}
      >
        전체
      </Checkbox>
      <Checkbox
        name="noMembership"
        value="0"
        colorScheme="blue"
        isChecked={membershipFilterChecked[1]}
        onChange={e => {
          setMembershipFilterChecked([
            membershipFilterChecked[0],
            e.target.checked,
            membershipFilterChecked[2],
            membershipFilterChecked[3],
            membershipFilterChecked[4],
          ]);
          // 멤버십 갱신

          const updatedMembershipResult = updateCheckboxPropertyList({
            filterChecked: membershipFilterChecked,
            propertyList: membershipList,
            target: 0,
            checked: e.target.checked, // onChange 일어난 이후의 체크상태,
            allPropertyList: [0, 1, 3, 6],
          });

          setMembershipList(updatedMembershipResult);
        }}
      >
        구독 안함
      </Checkbox>
      <Checkbox
        name="month1"
        value="1"
        colorScheme="blue"
        isChecked={membershipFilterChecked[2]}
        onChange={e => {
          setMembershipFilterChecked([
            membershipFilterChecked[0],
            membershipFilterChecked[1],
            e.target.checked,
            membershipFilterChecked[3],
            membershipFilterChecked[4],
          ]);

          const updatedMembershipResult = updateCheckboxPropertyList({
            filterChecked: membershipFilterChecked,
            propertyList: membershipList,
            target: 1,
            checked: e.target.checked, // onChange 일어난 이후의 체크상태,
            allPropertyList: [0, 1, 3, 6],
          });

          setMembershipList(updatedMembershipResult);
        }}
      >
        1개월
      </Checkbox>
      <Checkbox
        name="month3"
        value="3"
        colorScheme="blue"
        isChecked={membershipFilterChecked[3]}
        onChange={e => {
          setMembershipFilterChecked([
            membershipFilterChecked[0],
            membershipFilterChecked[1],
            membershipFilterChecked[2],
            e.target.checked,
            membershipFilterChecked[4],
          ]);

          const updatedMembershipResult = updateCheckboxPropertyList({
            filterChecked: membershipFilterChecked,
            propertyList: membershipList,
            target: 3,
            checked: e.target.checked, // onChange 일어난 이후의 체크상태,
            allPropertyList: [0, 1, 3, 6],
          });

          setMembershipList(updatedMembershipResult);
        }}
      >
        3개월
      </Checkbox>
      <Checkbox
        name="month6"
        value="6"
        colorScheme="blue"
        isChecked={membershipFilterChecked[4]}
        onChange={e => {
          setMembershipFilterChecked([
            membershipFilterChecked[0],
            membershipFilterChecked[1],
            membershipFilterChecked[2],
            membershipFilterChecked[3],
            e.target.checked,
          ]);

          const updatedMembershipResult = updateCheckboxPropertyList({
            filterChecked: membershipFilterChecked,
            propertyList: membershipList,
            target: 6,
            checked: e.target.checked, // onChange 일어난 이후의 체크상태,
            allPropertyList: [0, 1, 3, 6],
          });

          setMembershipList(updatedMembershipResult);
        }}
      >
        6개월
      </Checkbox>
    </Flex>
  );
};

export default MemberCheckbox;
