import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { deleteUserPlan } from 'api/auth/plan';
import UseResponseToast from 'Hook/UseResponseToast';
import React from 'react';
import { InfoDetailBox } from 'styles/ComponentStyle';

const RemoveMembershipBox = ({ user, membership }) => {
  const toast = useToast();
  const responseToast = UseResponseToast();

  const {
    isOpen: isRemoveMembershipModalOpen,
    onOpen: onRemoveMembershipModalOpen,
    onClose: onRemoveMembershipModalClose,
  } = useDisclosure();

  const removeMembershipPlan = async () => {
    const { statusCode, response, title, description, error } =
      await deleteUserPlan(user.user_uid);

    responseToast({
      statusCode,
      title,
      description,
    });

    if (error) {
      return;
    }

    window.location.reload();
  };

  return (
    <InfoDetailBox>
      <h4>구독상품</h4>
      <Flex gap={'10px'} alignItems={'center'}>
        <p>{membership.plan_name || '없음'}</p>
        {membership.plan_name && (
          <>
            <Button
              colorScheme="red"
              size="sm"
              onClick={onRemoveMembershipModalOpen}
            >
              해지하기
            </Button>
            <Modal
              isOpen={isRemoveMembershipModalOpen}
              onClose={onRemoveMembershipModalClose}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>구독상품을 해지 하시겠습니까?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{membership.plan_name}</ModalBody>

                <ModalFooter>
                  <Button mr={3} onClick={onRemoveMembershipModalClose}>
                    취소
                  </Button>
                  <Button colorScheme="red" onClick={removeMembershipPlan}>
                    해지
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )}
      </Flex>
    </InfoDetailBox>
  );
};

export default RemoveMembershipBox;
