import { useToast } from '@chakra-ui/react';

const UseResponseToast = () => {
  const toast = useToast();

  return ({ statusCode, title, description }) => {
    const dontNeedMessage = statusCode && !title && !description;

    if (dontNeedMessage) {
      return;
    }

    const toastConfig = {
      title: title || '',
      description: description || '',
      position: 'top-right',
      status: 'info',
      duration: 5000,
      isClosable: true,
    };

    if (statusCode >= 200 && statusCode < 300) {
      toastConfig.status = 'success';
    } else if (statusCode >= 400) {
      toastConfig.status = 'error';
    }

    toast(toastConfig);
  };
};

export default UseResponseToast;
