import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'Components/Header';
import {
  Box,
  useDisclosure,
  CloseButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useToast,
} from '@chakra-ui/react';
import Sider from './Sider.jsx';
import { AvatarBox } from './AvatarBox.jsx';
import { DrawerMenus } from './DrawerMenus.jsx';
import { LogOut } from 'styles/ComponentStyle.jsx';
import { AdminLogOut } from 'Hook/CheckedValid.jsx';

const Layout = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh">
      <Sider />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <Box
            transition="300ms ease"
            borderRight="1px"
            borderRightColor="gray.300"
            w="full"
            pos="fixed"
            h="full"
            paddingTop={{ base: '0' }}
            bg="#f9f9f9"
            overflow={'auto'}
            p="15px"
          >
            <Box>
              <CloseButton onClick={onClose} />
            </Box>
            <AvatarBox />
            <Box textAlign={'center'}>
              <LogOut onClick={AdminLogOut}>Logout</LogOut>
            </Box>
            <DrawerMenus onClose={onClose} />
          </Box>
        </DrawerContent>
      </Drawer>
      <Header onOpen={onOpen} />

      <Box
        backgroundColor="#f9f9f9"
        minH="100vh"
        marginLeft={{ base: '0', lg: '240px' }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
