import { Flex } from '@chakra-ui/react';
import React from 'react';
import styled from 'styled-components';
import { QuestionBox } from 'styles/ComponentStyle';

const QuestionLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  > input[type='checkbox'] {
    width: 18px;
    height: 18px;

    &:checked {
      background-color: $main;
    }
  }

  &:hover {
    font-weight: 600;
  }
`;

const QuestionStatusCheckBox = ({
  setCheckedValue,
  statusChecked,
  setStatusChecked,
}) => {
  const ChangeCheckedValue = e => {
    setCheckedValue(e.target.value);
  };
  return (
    <QuestionBox>
      <h4>상태</h4>
      <Flex direction={{ base: 'column', sm: 'row' }} gridGap="15px">
        <QuestionLabel htmlFor="answered">
          <input
            type="checkbox"
            value="answered"
            id="answered"
            checked={statusChecked[0]}
            onChange={e => {
              setStatusChecked([true, false, false]);
              ChangeCheckedValue(e);
            }}
          />
          답변 완료
        </QuestionLabel>
        <QuestionLabel htmlFor="checked">
          <input
            type="checkbox"
            value="checked"
            id="checked"
            checked={statusChecked[1]}
            onChange={e => {
              setStatusChecked([false, true, false]);
              ChangeCheckedValue(e);
            }}
          />
          확인
        </QuestionLabel>
        <QuestionLabel htmlFor="unchecked">
          <input
            type="checkbox"
            value="unchecked"
            id="unchecked"
            checked={statusChecked[2]}
            onChange={e => {
              setStatusChecked([false, false, true]);
              ChangeCheckedValue(e);
            }}
          />
          미확인
        </QuestionLabel>
      </Flex>
    </QuestionBox>
  );
};

export default QuestionStatusCheckBox;
