import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  useToast,
  useDisclosure,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Back, QuestionBox } from 'styles/ComponentStyle';
import * as server from 'config/Config';
import { BtnBox, ModifyBtn, DeleteCancelBtn } from 'styles/ComponentStyle';
import dayjs from 'dayjs';
import QuestionStatusCheckBox from './QuestionStatusCheckBox';
import { getInquiry, updateInquiry } from 'api/auth/inquiry';
import UseResponseToast from 'Hook/UseResponseToast';
import { useRecoilState } from 'recoil';
import { adminState } from 'config/Recoil';

const QuestionDetail = () => {
  const [admin, setAdmin] = useRecoilState(adminState);
  const toast = useToast();
  const responseToast = UseResponseToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const { id } = useParams();

  const [inquiry, setInquiry] = useState('');
  const [statusChecked, setStatusChecked] = useState([false, false, false]);
  const [checkedValue, setCheckedValue] = useState('');

  const ModifyStatus = async () => {
    const status = inquiry.status;
    if (!checkedValue) {
      toast({
        title: '문의상태가 수정되지 않았습니다.',
        description: '체크한 상태를 확인해주세요!',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (checkedValue !== status) {
      const { response, title, description, statusCode, error } =
        await updateInquiry({
          inquiry_uid: id,
          status: checkedValue,
        });

      responseToast({ statusCode, title, description });
      if (error) {
        return;
      }
      navigate('/questions');
    }
  };

  const fetchData = async () => {
    const { response, title, description, statusCode, error } =
      await getInquiry({
        page: 1,
        count: 50,
      });

    responseToast({ title, description, statusCode });

    if (error) {
      return;
    }

    const data = response.data.data;

    const FilterData = data.filter(item => item.inquiry_uid === Number(id));
    const inquiry = FilterData[0];
    const status = inquiry.status;

    switch (status) {
      case 'answered':
        setStatusChecked([true, false, false]);
        break;
      case 'checked':
        setStatusChecked([false, true, false]);
        break;
      case 'unchecked':
        setStatusChecked([false, false, true]);
        break;
      default:
    }

    setInquiry(inquiry);
  };

  useEffect(() => {
    fetchData(); // fetchData는 문의 상세 데이터를 불러오는 함수
  }, []);

  return (
    <>
      <Box p={{ base: '0 36px', md: '120px 64px' }}>
        <Box maxW="1200px" m="0 auto">
          <QuestionBox>
            <h4>문의 날짜</h4>
            <p>{dayjs(inquiry.date).format('YYYY-MM-DD')}</p>
          </QuestionBox>
          <QuestionBox>
            <h4>문의자</h4>
            <p>{inquiry.name}</p>
          </QuestionBox>
          <QuestionBox>
            <h4>이메일</h4>
            <p>{inquiry.email}</p>
          </QuestionBox>
          <QuestionBox>
            <h4>문의 유형</h4>
            <p>{inquiry.category}</p>
          </QuestionBox>
          <QuestionBox>
            <h4>문의 제목</h4>
            <p>{inquiry.title}</p>
          </QuestionBox>
          <QuestionBox style={{ minHeight: '200px' }}>
            <h4>문의 내용</h4>
            <p>{inquiry.content}</p>
          </QuestionBox>

          <QuestionStatusCheckBox
            setCheckedValue={setCheckedValue}
            statusChecked={statusChecked}
            setStatusChecked={setStatusChecked}
          />

          <BtnBox>
            <Back onClick={() => navigate(-1)}>뒤로 가기</Back>
            <ModifyBtn onClick={onOpen}>수정</ModifyBtn>
          </BtnBox>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody
            textAlign={'center'}
            fontSize="1.2rem"
            fontWeight={600}
            padding="20px 24px 10px"
          >
            문의 상태를 수정하시겠습니까?
          </ModalBody>
          <ModalFooter justifyContent={'center'}>
            <HStack>
              <ModifyBtn onClick={ModifyStatus}>확인</ModifyBtn>
              <DeleteCancelBtn cancel onClick={onClose}>
                취소
              </DeleteCancelBtn>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default QuestionDetail;
