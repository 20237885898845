import getAuthAxios from '../../utill/getAuthAxios';

const authAxios = getAuthAxios();

export const createAutoGPTCoupon = ({
  campaign_name,
  desc,
  plan,
  plan_month,
  many,
}) => {
  return authAxios.post(`/autogpt/coupon`, {
    campaign_name,
    desc,
    plan,
    plan_month,
    many,
  });
};

export const updateAutoGPTCoupon = ({
  coupon_uid,
  campaign_name,
  desc,
  plan,
}) => {
  return authAxios.put(`/autogpt/coupon?coupon_uid=${coupon_uid}`, {
    campaign_name,
    desc,
    plan,
  });
};

export const deleteAutoGPTCoupon = coupon_uid => {
  return authAxios.delete(`/autogpt/coupon?coupon_uid=${coupon_uid}`);
};

export const getAutoGPTCouponDetail = ({ coupon_uid }) => {
  return authAxios.get(`/autogpt/coupon/detail?coupon_uid=${coupon_uid}`);
};

export const getAutoGPTCouponList = ({
  page,
  count,
  campaign_name,
  plan,
  coupon_uid,
  is_used,
  user,
  startDate,
  endDate,
}) => {
  return authAxios.post('/autogpt/coupon/list', {
    page,
    count,
    //아래 사항들은 전부 옵셔녈
    campaign_name, //캠페인명
    plan, //개월수
    coupon_uid, // 쿠폰 uid
    is_used, // 사용 여부 (1 또는 0? -> 물어보자)
    user, // 유저 이메일
    startDate, // 쿠폰 생성일 범위
    endDate, // 쿠폰 생성일
  });
};
