import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SearchIcon } from '@chakra-ui/icons';
import { getUserList } from 'api/auth/user';
import { createGroup } from 'api/auth/group';
import UseResponseToast from 'Hook/UseResponseToast';
import { useRecoilState } from 'recoil';
import { adminState } from 'config/Recoil';

const AddGroupModal = ({
  isAddGroupModalOpen,
  setIsAddGroupModalOpen,
  setIsExistEmail,
  isExistEmail,
  setSearchList,
  searchList,
  setIdList,
}) => {
  const toast = useToast();
  const [admin, setAdmin] = useRecoilState(adminState);
  const responseToast = UseResponseToast();

  const [groupLeaderUid, setGroupLeaderUid] = useState('');
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const closeAddGroupModal = () => {
    setIsAddGroupModalOpen(false);
    setIsExistEmail(prev => '');
    reset({ groupEmail: '' });
  };

  const searchAccount = async () => {
    const { statusCode, response, title, description, error } =
      await getUserList({
        page: 1,
        count: 1,
        membershipList: [0, 1, 3, 6],
        serviceList: ['iamport', 'innopay', 'nopassbook', 'none'],
        keyword: watch('groupEmail'),
      });

    responseToast({ title, description, statusCode });

    if (error) {
      return;
    }

    const existUser = response.data.data.find(
      userData => userData.user.email === watch('groupEmail')
    );

    if (existUser) {
      setIsExistEmail(true);

      setGroupLeaderUid(existUser.user.user_uid);
      return;
    }

    setIsExistEmail(false);
  };

  const addGroup = handleSubmit(async data => {
    const { response, title, description, statusCode, error } =
      await getUserList({
        page: 1,
        count: 1,
        membershipList: [0, 1, 3, 6],
        serviceList: ['iamport', 'innopay', 'nopassbook', 'none'],
        keyword: watch('groupEmail'),
      });

    responseToast({ title, description, statusCode });

    if (error) {
      return;
    }

    const existUser = response.data.data.find(
      userData => userData.user.email === watch('groupEmail')
    );

    if (existUser) {
      setIsExistEmail(true);
      setGroupLeaderUid(existUser.user.user_uid);
    } else {
      setIsExistEmail(false);
      return;
    }

    const {
      statusCode: createGroupStatusCode,
      response: createGroupResponse,
      title: createGroupTitle,
      description: createGroupDescription,
      error: createGroupError,
    } = await createGroup({
      name: data.groupName,
      user_uid: groupLeaderUid,
    });

    responseToast({
      statusCode: createGroupStatusCode,
      title: createGroupTitle,
      description: createGroupDescription,
    });

    if (createGroupError) {
      return;
    }

    setSearchList([...searchList, createGroupResponse.data.data.group]);

    setIdList(prev => {
      return [...prev, createGroupResponse.data.data.group.uid];
    });

    closeAddGroupModal();
  });

  return (
    <Modal isOpen={isAddGroupModalOpen} onClose={closeAddGroupModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>그룹 생성하기</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent={'center'} flexDirection={'column'}>
            <Flex alignItems={'center'}>
              <label style={{ width: '80px' }}>그룹명</label>
              <Input
                placeholder="group name"
                {...register('groupName', { required: true })}
              />
            </Flex>
            <Flex alignItems={'center'} marginTop={'20px'}>
              <label style={{ width: '80px', marginRight: '10px' }}>
                그룹장 이메일
              </label>
              <Input
                placeholder="group email"
                {...register('groupEmail', { required: true })}
              />
              <Button onClick={searchAccount}>
                <SearchIcon />
              </Button>
            </Flex>
            <Flex>
              <div style={{ width: '80px' }}></div>
              <FormControl isInvalid={!isExistEmail}>
                {isExistEmail && (
                  <FormHelperText>존재하는 이메일 입니다.</FormHelperText>
                )}
                {isExistEmail === null && ''}
                {isExistEmail === false && (
                  <FormErrorMessage>
                    존재하지 않는 이메일 입니다.
                  </FormErrorMessage>
                )}
              </FormControl>
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={closeAddGroupModal}>
            닫기
          </Button>
          <Button colorScheme="blue" onClick={addGroup}>
            생성하기
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddGroupModal;
