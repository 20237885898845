import { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  Input,
  Select,
  Skeleton,
  Stack,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { SerialInputBox } from 'styles/ComponentStyle';

import GeneratedSerialResult from './GeneratedSerialResult';
import { createCoupon } from 'api/auth/coupon';
import UseResponseToast from 'Hook/UseResponseToast';

const CreateSerial = () => {
  const toast = useToast();
  const responseToast = UseResponseToast();

  const [StringStates, setStringStates] = useState({
    campaign: '',
    description: '',
  });

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState(0);
  const [data, setData] = useState('');
  const { campaign, description } = StringStates;

  const HandleStringStates = e => {
    setStringStates({ ...StringStates, [e.target.id]: e.target.value });
  };

  const MakeSerial = async () => {
    const objectValue = Object.values(StringStates);
    const isBlank = objectValue.includes('');

    if (isBlank || amount === 0 || date === 0) {
      toast({
        title: '실패',
        description: `빈 칸이 남있거나 숫자가 설정되지 않았습니다.`,
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (!isBlank && amount > 0 && date > 0) {
      setLoading(true);

      const {
        response,
        title,
        description: responseDescription,
        statusCode,
        error,
      } = await createCoupon({
        campaign_name: campaign,
        desc: description,
        plan: 0,
        date: date,
        many: amount,
      });

      responseToast({
        title,
        description,
        statusCode,
      });

      if (error) {
        return;
      }

      const resData = response.data.data;
      setData(resData);

      setLoading(false);
    }
  };

  return (
    <>
      <Box className="MemberContainer">
        <Box
          maxW="1300px"
          m="0 auto"
          bg="#fff"
          padding="36px"
          boxShadow="rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"
        >
          <SerialInputBox>
            <label htmlFor="campaign">캠페인명</label>
            <Input
              type="text"
              id="campaign"
              value={campaign}
              onChange={HandleStringStates}
            ></Input>
          </SerialInputBox>
          <SerialInputBox>
            <label htmlFor="description">상세 설명</label>
            <Input
              type="text"
              id="description"
              value={description}
              onChange={HandleStringStates}
            ></Input>
          </SerialInputBox>

          <Flex justify={'space-between'} gridGap="20px">
            <SerialInputBox w="50%">
              <label htmlFor="service">혜택구분</label>
              <Select
                className="selectOption"
                id="plan"
                value={date}
                onChange={e => setDate(Number(e.target.value))}
              >
                <option value={0} disabled>
                  혜택을 선택해주세요
                </option>
                <option value={14}>2주일</option>
                <option value={31}>1개월</option>
                <option value={92}>3개월</option>
                <option value={183}>6개월</option>
              </Select>
            </SerialInputBox>
            <SerialInputBox w="50%">
              <label htmlFor="amount">발급수량</label>
              <NumberInput
                id="amount"
                w="100%"
                min={0}
                value={amount}
                onChange={value => setAmount(Number(value))}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </SerialInputBox>
          </Flex>

          <Box textAlign={'right'}>
            <Button
              transition={'all 300ms ease-in-out'}
              background={'#43655A'}
              color="#fff"
              _hover={{ bg: '#618180' }}
              onClick={MakeSerial}
            >
              생성하기
            </Button>
          </Box>
        </Box>

        {/* 시리얼 결과 테이블 */}
        {loading && (
          <Box p="36px">
            <Stack>
              <Skeleton height="30px" />
              <Skeleton height="30px" />
              <Skeleton height="30px" />
              <Skeleton height="30px" />
              <Skeleton height="30px" />
            </Stack>
          </Box>
        )}
        {!loading && data && <GeneratedSerialResult data={data} />}
      </Box>
    </>
  );
};

export default CreateSerial;
