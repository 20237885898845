export const getToastMessage = ({ method, path }) => {
  let title = '';
  let description = '';

  const removedQuerystringPath = path.split('?')[0];

  const trimmedPath = removedQuerystringPath.replace(/^\/api\/v2\/admin/, '');

  const routeKey = `${method} ${trimmedPath}`;

  console.log(routeKey);

  switch (true) {
    case routeKey.includes('post /autogpt/coupon'):
      title = 'AutoGPT 쿠폰 생성 성공';
      description = 'AutoGPT 쿠폰을 성공적으로 생성했습니다.';
      break;
    case routeKey.includes('put /autogpt/coupon'):
      title = 'AutoGPT 쿠폰 업데이트 성공';
      description = 'AutoGPT 쿠폰을 성공적으로 업데이트했습니다.';
      break;
    case routeKey.includes('delete /autogpt/coupon'):
      title = 'AutoGPT 쿠폰 삭제 성공';
      description = 'AutoGPT 쿠폰을 성공적으로 삭제했습니다.';
      break;
    // case routeKey.includes('get /autogpt/coupon/detail'):
    //   title = 'AutoGPT 쿠폰 상세 조회 성공';
    //   description = 'AutoGPT 쿠폰의 상세 정보를 성공적으로 조회했습니다.';
    //   break;
    // case routeKey.includes('post /autogpt/coupon/list'):
    //   title = 'AutoGPT 쿠폰 목록 조회 성공';
    //   description = 'AutoGPT 쿠폰 목록을 성공적으로 조회했습니다.';
    //   break;
    case routeKey.includes('post /coupon'):
      title = '쿠폰 생성 성공';
      description = '쿠폰을 성공적으로 생성했습니다.';
      break;
    case routeKey.includes('put /coupon'):
      title = '쿠폰 업데이트 성공';
      description = '쿠폰을 성공적으로 업데이트했습니다.';
      break;
    case routeKey.includes('delete /coupon'):
      title = '쿠폰 삭제 성공';
      description = '쿠폰을 성공적으로 삭제했습니다.';
      break;
    // case routeKey.includes('get /coupon/detail'):
    //   title = '쿠폰 상세 조회 성공';
    //   description = '쿠폰의 상세 정보를 성공적으로 조회했습니다.';
    //   break;
    // case routeKey.includes('post /coupon/list'):
    //   title = '쿠폰 목록 조회 성공';
    //   description = '쿠폰 목록을 성공적으로 조회했습니다.';
    //   break;
    // case routeKey.includes('post /user/list/search'):
    //   title = '사용자 목록 검색 성공';
    //   description = '사용자 목록을 성공적으로 검색했습니다.';
    //   break;
    // case routeKey.includes('get /user'):
    //   title = '사용자 기본 정보 조회 성공';
    //   description = '사용자의 기본 정보를 성공적으로 조회했습니다.';
    //   break;
    case routeKey.includes('delete /user'):
      title = '사용자 삭제 성공';
      description = '사용자를 성공적으로 삭제했습니다.';
      break;
    // case routeKey.includes('get /user/profile/'):
    //   title = '사용자 프로필 조회 성공';
    //   description = '사용자의 프로필을 성공적으로 조회했습니다.';
    //   break;
    // case routeKey.includes('post /inquiry/list/search'):
    //   title = '문의 목록 검색 성공';
    //   description = '문의 목록을 성공적으로 검색했습니다.';
    //   break;
    case routeKey.includes('put /inquiry/'):
      title = '문의 상태 업데이트 성공';
      description = '문의 상태를 성공적으로 업데이트했습니다.';
      break;
    // case routeKey.includes('get /profile'):
    //   title = '프로필 조회 성공';
    //   description = '프로필을 성공적으로 조회했습니다.';
    //   break;
    // case routeKey.includes('get /user/plan/log'):
    //   title = '사용자 플랜 로그 조회 성공';
    //   description = '사용자의 플랜 로그를 성공적으로 조회했습니다.';
    //   break;
    case routeKey.includes('put /user/plan'):
      title = '사용자 플랜 업데이트 성공';
      description = '사용자의 플랜을 성공적으로 업데이트했습니다.';
      break;
    case routeKey.includes('put /user/plan/drawingel'):
      title = '사용자 드로잉젤 토큰 업데이트 성공';
      description = '사용자의 드로잉젤 토큰을 성공적으로 업데이트했습니다.';
      break;
    case routeKey.includes('put /user/plan/chatinggel'):
      title = '사용자 채팅젤 토큰 업데이트 성공';
      description = '사용자의 채팅젤 토큰을 성공적으로 업데이트했습니다.';
      break;
    case routeKey.includes('put /user/plan/autogpt'):
      title = '사용자 AutoGPT 플랜 업데이트 성공';
      description = '사용자의 AutoGPT 플랜을 성공적으로 업데이트했습니다.';
      break;
    case routeKey.includes('put /user/plan/writinggel'):
      title = '사용자 라이팅젤 토큰 업데이트 성공';
      description = '사용자의 라이팅젤 토큰을 성공적으로 업데이트했습니다.';
      break;
    case routeKey.includes('put /user/plan/videogel'):
      title = '사용자 비디오젤 토큰 업데이트 성공';
      description = '사용자의 비디오젤 토큰을 성공적으로 업데이트했습니다.';
      break;
    // case routeKey.includes('get /user/plan/autogpt'):
    //   title = '사용자 AutoGPT 플랜 조회 성공';
    //   description = '사용자의 AutoGPT 플랜을 성공적으로 조회했습니다.';
    //   break;
    case routeKey.includes('delete /user/plan'):
      title = '사용자 플랜 삭제 성공';
      description = '사용자의 플랜을 성공적으로 삭제했습니다.';
      break;
    case routeKey.includes('post /prompt'):
      title = '프롬프트 생성 성공';
      description = '프롬프트를 성공적으로 생성했습니다.';
      break;
    // case routeKey.includes('get /prompt/list'):
    //   title = '프롬프트 목록 조회 성공';
    //   description = '프롬프트 목록을 성공적으로 조회했습니다.';
    //   break;
    case routeKey.includes('put /prompt/'):
      title = '프롬프트 업데이트 성공';
      description = '프롬프트를 성공적으로 업데이트했습니다.';
      break;
    case routeKey.includes('delete /prompt/'):
      title = '프롬프트 삭제 성공';
      description = '프롬프트를 성공적으로 삭제했습니다.';
      break;
    // case routeKey.includes('get /prompt/'):
    //   title = '프롬프트 조회 성공';
    //   description = '프롬프트를 성공적으로 조회했습니다.';
    //   break;

    case routeKey.includes('post /group/add-user'):
      title = '그룹에 유저 추가 성공';
      description = '그룹에 성공적으로 유저를 추가했습니다.';
      break;
    case routeKey.includes('post /group'):
      title = '그룹 생성 성공';
      description = '그룹을 성공적으로 생성했습니다.';
      break;
    // case routeKey.includes('get /group'):
    //   title = '그룹 목록 조회 성공';
    //   description = '그룹 목록을 성공적으로 조회했습니다.';
    //   break;
    // case routeKey.includes('get /group/search'):
    //   title = '그룹 검색 성공';
    //   description = '그룹을 검색했습니다.';
    //   break;

    // case routeKey.includes('get /group/'):
    //   title = '그룹 uid로 그룹 조회 성공';
    //   description = '그룹을 성공적으로 조회했습니다.';
    //   break;

    case routeKey.includes('delete /group'):
      title = '그룹 삭제 성공';
      description = '그룹 삭제를 성공했습니다.';
      break;
    case routeKey.includes('delete /group/user'):
      title = '그룹의 유저 삭제';
      description = '그룹의 유저를 삭제했습니다.';
      break;
    case routeKey.includes('delete /prompt_npc/'):
      title = '그룹 삭제 성공';
      description = `그룹 삭제에 성공했습니다.`;
      break;

    // case routeKey.includes('get /user/profile'):
    //   title = '사용자 프로필 조회 성공';
    //   description = '사용자의 프로필을 성공적으로 조회했습니다.';
    //   break;
    default:
      // title = '정의되지 않은 요청메시지';
      // description = '요청메시지를 정의해주세요';
      break;
  }

  // // case rootKey.includes('get /group/:group_uid/user'):
  // const [methodPart, pathPart] = routeKey.split(' ', 2);

  // const splitted = pathPart.split('/');
  // // 2) 메서드가 'get' 인지(대소문자 구분 없음) 확인
  // if (
  //   methodPart.toLowerCase() === 'get' &&
  //   splitted.length === 4 && // 총 4조각인지
  //   splitted[1] === 'group' && // 두 번째 조각이 'group'인지
  //   splitted[3] === 'user'
  // ) {
  //   title = '그룹의 유저 검색';
  //   description = '그룹의 유저 검색 성공';
  // }

  return {
    title,
    description,
  };
};
