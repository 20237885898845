import React, { Suspense, useEffect, useState, lazy } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Checkbox,
  useToast,
  HStack,
  useDisclosure,
  Skeleton,
} from '@chakra-ui/react';
import { PlusSquareIcon, DeleteIcon } from '@chakra-ui/icons';
import IsDeleteModal from 'Common/IsDeleteModal';
import { Pagination } from 'Common/Pagination';
import { deletePrompt, getPromptList } from 'api/auth/prompt';
import UseResponseToast from 'Hook/UseResponseToast';
import { useRecoilState } from 'recoil';
import { adminState } from 'config/Recoil';

const PromptTable = lazy(() => import('./PromptTable'));

const Prompts = () => {
  const [admin, setAdmin] = useRecoilState(adminState);
  const toast = useToast();
  const responseToast = UseResponseToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  //체크된 아이템
  const [checkedItems, setCheckedItems] = useState([]);
  //체크용 id 리스트
  const [idList, setIdList] = useState([]);
  //프롬프트 리스트
  const [List, setList] = useState([]);
  //현재 페이지
  // const [currentPage, setCurrent] = useState(1);
  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page')) || 1;
  const offset = (currentPage - 1) * 30;
  const [maxPage, setMaxPage] = useState(1);

  const isIndeterminate = checkedItems.some(Boolean);

  const CheckAll = e => {
    setCheckedItems(e.target.checked ? idList : []);
  };

  const CheckEach = (e, id) => {
    if (e.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter(item => item !== id));
    }
  };

  const DeletePrompt = async () => {
    const checkedArray = idList.filter(item => checkedItems.includes(item));

    if (checkedArray.length <= 0) {
      toast({
        title: '선택한 프롬프트가 없어요!',
        description: '삭제할 프롬프트를 선택해주세요.',
        position: 'top-right',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (checkedArray.length === 1) {
      const { statusCode, response, title, description, error } =
        await deletePrompt(checkedArray[0]);

      responseToast({ statusCode, title, description });

      if (error) {
        return;
      }

      navigate(0);
      return;
    }

    Promise.all(
      checkedArray.map(async param => {
        const { statusCode, response, title, description } = await deletePrompt(
          checkedArray[0]
        );
        return { statusCode, response, title, description };
      })
    )
      .then(({ statusCode, title, description }) => {
        responseToast({ statusCode, title, description });
        navigate(0);
      })
      .catch(error => {
        responseToast({
          statusCode: error.response.status,
          title: '프롬프트 삭제 실패',
          description: '프롬프트 삭제에 실패했습니다.',
        });
      });
  };

  const fetchData = async () => {
    setLoading(true);

    const { statusCode, response, title, description, error } =
      await getPromptList({
        page: currentPage,
        count: 50,
        order_by: 'create_at:DESC',
      });

    responseToast({ title, description, statusCode });

    if (error) {
      return;
    }

    const list = response.data.data;

    const orderList = list.sort(
      (a, b) => new Date(b.update_at) - new Date(a.update_at)
    );

    let idList = [];
    const ids = list.map((item, i) => (idList[i] = item.uid));
    setList(orderList);
    setIdList(ids);

    setMaxPage(response.data.config.maxPage);

    setLoading(false);
  };

  useEffect(() => {
    fetchData(); // fetchData는 프롬프트 데이터를 불러오는 함수
  }, [currentPage]);

  return (
    <>
      <Box maxW="1600px" m="0 auto" padding={{ base: '48px 24px', md: '48px' }}>
        <HStack justify="flex-end" mb={25} spacing="15px">
          <Link to="/prompts/create">
            <PlusSquareIcon w={5} h={5} style={{ cursor: 'pointer' }} />
          </Link>
          <DeleteIcon
            onClick={onOpen}
            w={5}
            h={5}
            style={{ cursor: 'pointer' }}
          />
        </HStack>
        <Box
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': {
              //스크롤바 전체영역
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              //스크롤바 움직이는 영역
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              //스크롤
              backgroundColor: '#E6F4F1',
              borderRadius: '5px',
            },
          }}
        >
          <Suspense>
            <Skeleton h="100%" isLoaded={!loading}>
              <table className="CustomTableStyle">
                <thead>
                  <tr className="Custom-tr Custom-thead-tr">
                    <th className="CheckBox textCenter">
                      <Checkbox
                        name="all"
                        value="all"
                        colorScheme="blue"
                        isChecked={checkedItems.length === idList.length}
                        isIndeterminate={isIndeterminate}
                        onChange={CheckAll}
                      />
                    </th>
                    <th className="Custom-th1 textLeft">서비스 항목</th>
                    <th className="Custom-th2">작성자</th>
                    <th className="Custom-th3">작성일자</th>
                    <th className="Custom-th3">최종수정일자</th>
                    <th className="Custom-th4">상세보기</th>
                  </tr>
                </thead>
                <PromptTable
                  offset={offset}
                  List={List}
                  checkedItems={checkedItems}
                  CheckEach={CheckEach}
                />
              </table>
            </Skeleton>
          </Suspense>
        </Box>
        <Pagination currentPage={currentPage} maxPage={maxPage} />
      </Box>
      <IsDeleteModal isOpen={isOpen} onClose={onClose} Delete={DeletePrompt} />
    </>
  );
};

export default Prompts;
