import getAuthAxios from 'utill/getAuthAxios';

const authAxios = getAuthAxios();

export const getUserPlanLog = user_uid => {
  return authAxios.get(`/user/plan/log?user_uid=${user_uid}`);
};

export const updateUserPlan = ({ user_uid, plan, isNoPassbook, endDate }) => {
  return authAxios.put(`/user/plan?user_uid=${user_uid}`, {
    plan,
    isNoPassbook,
    endDate,
  });
};

export const updateUserAutogptPlan = ({ user_uid, plan }) => {
  return authAxios.put(`/user/plan/autogpt?user_uid=${user_uid}`, {
    plan,
  });
};

export const updateUserToken = ({ user_uid, token, tokenType }) => {
  return authAxios.put(`/user/plan/${tokenType}?user_uid=${user_uid}`, {
    token,
  });
};

export const getUserAutogptPlan = user_uid => {
  return authAxios.get(`/user/plan/autogpt?user_uid=${user_uid}`);
};

export const deleteUserPlan = user_uid => {
  return authAxios.delete(`/user/plan?user_uid=${user_uid}`);
};
