import axios from 'axios';
import { SERVER_URL } from 'config/Config';
import { getToastMessage } from 'utill/toastMessage';

const getAuthAxios = () => {
  const authAxios = axios.create({
    baseURL: SERVER_URL,
  });

  // 요청 인터셉터
  authAxios.interceptors.request.use(
    config => {
      const token = localStorage.getItem('admin_token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );

  // 응답 인터셉터 추가하기
  authAxios.interceptors.response.use(
    function (response) {
      // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
      // 응답 데이터가 있는 작업 수행

      // response.config.method -> 메소드
      // response.data.config.path -> 오리진 제외한 path

      const { title, description } = getToastMessage({
        method: response.config.method,
        path: response.data.config.path,
      });

      return {
        statusCode: response.status,
        response,
        title,
        description,
        error: undefined,
      };
    },
    function (error) {
      // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
      // 응답 오류가 있는 작업 수행

      console.error(error);

      return {
        statusCode: error.response.status,
        response: undefined,
        title: error.response.data.message,
        description: error.message,
        error,
      };
    }
  );

  return authAxios;
};

export default getAuthAxios;
