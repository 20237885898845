import React from 'react';
import { Box } from '@chakra-ui/react';
import '../styles/style.scss';

import { AvatarBox } from './AvatarBox';
import { LogOut } from 'styles/ComponentStyle';
import SiderMenus from './SiderMenus';
import { AdminLogOut } from 'Hook/CheckedValid';

const Sider = () => {
  return (
    <Box
      transition="300ms ease"
      w={{ base: 'full', lg: '240px' }}
      flexDirection="column"
      alignItems="center"
      pos="fixed"
      top={0}
      h="full"
      bg="#474554"
      color="#fff"
      display={{ base: 'none', lg: 'flex' }}
      boxShadow="rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"
      p="10px"
    >
      <Box>
        <AvatarBox />
        <Box textAlign={'center'}>
          <LogOut onClick={AdminLogOut}>Logout</LogOut>
        </Box>
      </Box>
      <SiderMenus />
    </Box>
  );
};

export default Sider;
