import { Text, Flex, Tooltip, IconButton } from '@chakra-ui/react';
import {
  ArrowLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowRightIcon,
} from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

export const Pagination = ({ currentPage, maxPage }) => {
  const navigate = useNavigate();

  const movePrevPage = () => {
    if (currentPage === 1) {
      return;
    }
    navigate(`?page=${currentPage - 1 || 1}`);
  };

  const moveNextPage = () => {
    if (currentPage === maxPage) {
      return;
    }
    navigate(`?page=${currentPage + 1}`);
  };

  const moveFirstPage = () => {
    if (currentPage === 1) {
      return;
    }
    navigate(`?page=${1}`);
  };

  const moveLastPage = () => {
    if (currentPage === maxPage) {
      return;
    }
    navigate(`?page=${maxPage}`);
  };

  return (
    <Flex m={4} align="center" justify="center">
      <Flex justify="space-between">
        <Tooltip label="First Page">
          <IconButton
            size="sm"
            onClick={moveFirstPage}
            icon={<ArrowLeftIcon h={3} w={3} />}
            isDisabled={currentPage === 1}
            mr={4}
          />
        </Tooltip>
        <Tooltip label="Previous Page">
          <IconButton
            size="sm"
            onClick={movePrevPage}
            isDisabled={currentPage === 1}
            icon={<ChevronLeftIcon h={6} w={6} />}
          />
        </Tooltip>
      </Flex>

      <Flex align="center" flexShrink="0" ml={5} mr={5}>
        <Text>
          <Text fontWeight="bold" as="span">
            {currentPage}
          </Text>{' '}
          of{' '}
          <Text fontWeight="bold" as="span">
            {maxPage}
          </Text>
        </Text>
      </Flex>

      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            size="sm"
            onClick={moveNextPage}
            isDisabled={currentPage === maxPage}
            icon={<ChevronRightIcon h={6} w={6} />}
          />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton
            size="sm"
            isDisabled={currentPage === maxPage}
            onClick={moveLastPage}
            icon={<ArrowRightIcon h={3} w={3} />}
            ml={4}
          />
        </Tooltip>
      </Flex>
    </Flex>
  );
};
