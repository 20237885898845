import { Button, Checkbox, Select, useToast } from '@chakra-ui/react';
import { updateUserPlan } from 'api/auth/plan';
import dayjs from 'dayjs';
import UseResponseToast from 'Hook/UseResponseToast';
import React, { useState } from 'react';
import { InfoDetailBox } from 'styles/ComponentStyle';

const AddMembershipBox = ({ user, membership }) => {
  const responseToast = UseResponseToast();
  const [membershipInput, setMembershipInput] = useState({
    plan: 1,
    isNoPassbook: false,
    endDate: dayjs().format('YYYY-MM-DD'),
  });

  const updateMembership = async () => {
    const { statusCode, response, title, description, error } =
      await updateUserPlan({
        user_uid: user.user_uid,
        ...membershipInput,
      });

    responseToast({ statusCode, title, description });

    if (error) {
      return;
    }

    window.location.reload();
  };

  return (
    <InfoDetailBox>
      <h4>멤버십 적용</h4>

      <Select
        onChange={e => {
          console.log(e.target.value);
          setMembershipInput(prev => ({
            ...prev,
            plan: parseInt(e.target.value),
          }));
        }}
        maxW={'300px'}
      >
        <option value={1}>1개월</option>
        <option value={3}>3개월</option>
        <option value={6}>6개월</option>
      </Select>

      <Button colorScheme="blue" px={'35px'} w={'100px'}>
        <Checkbox
          value={membershipInput.isNoPassbook}
          onChange={e => {
            setMembershipInput(prev => ({
              ...prev,
              isNoPassbook: !prev.isNoPassbook,
            }));
          }}
        >
          무통장
        </Checkbox>
      </Button>
      <Button colorScheme="green" px={'30px'} onClick={updateMembership}>
        수정
      </Button>
    </InfoDetailBox>
  );
};

export default AddMembershipBox;
