import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Flex } from '@chakra-ui/react';
import React from 'react';

const SearchMembersInput = ({ keyword, setKeyword, fetchUsersData }) => {
  return (
    <Box margin="15px 0">
      <form>
        <Flex
          className="SearchFlex"
          alignItems="center"
          justify="space-between"
          direction={{ base: 'column', sm: 'row' }}
        >
          <input
            type={'text'}
            placeholder="검색할 키워드를 입력해주세요"
            value={keyword || ''}
            onChange={e => {
              e.stopPropagation();
              setKeyword(e.target.value);
            }}
          />
          <Button
            type="submit"
            onClick={e => {
              e.preventDefault();
              // setCurrent(1);
              fetchUsersData();
            }}
          >
            <SearchIcon />
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default SearchMembersInput;
