import { atom } from 'recoil';

export const adminState = atom({
  key: 'adminState', //구분 되는 고유한 키
  default: {
    token: '',
    id: '',
    name: '',
    uid: '',
    is_root: '',
    login_at: '',
    create_at: '',
    update_at: '',
    delete_at: '',
  }, //기본 값
});

export const userStatisticsState = atom({
  key: 'userStatisticsState',
  default: {
    signup: '',
    currentSum: '',
    currentOne: '',
    currentThree: '',
    currentSix: '',
  },
});

// export const
