import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Box, Flex, Select, useDisclosure, Divider } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import dayjs from 'dayjs';
import { ExcelDownBtn, ResetBtn } from 'styles/ComponentStyle';
import { requestUserList } from '../../../Hook/requestUserList';
import MemberCheckbox from './MemberCheckbox';
import SelectedCheckBox from 'Common/SelectedCheckBox';
import SearchMembersInput from './SearchMembersInput';
import TableContainerBox from './TableContainerBox';
import PaymentCheckbox from './PaymentCheckbox';

const Members = () => {
  const headers = [
    { label: '회원명', key: 'user.name' },
    { label: '이메일 주소', key: 'user.email' },
    { label: '가입일자', key: 'user.create_at' },
    { label: '최근 로그인', key: 'user.login_at' },
    { label: '구독상품', key: 'membership.plan_name' },
    { label: '결제일자', key: 'membership.start_date' },
  ];

  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page')) || 1;
  const [maxPage, setMaxPage] = useState(0);
  const [startDate, setStartDate] = useState(new Date('January 1, 2021'));

  const renderDayContents = date => {
    return <span title={date}>{date}</span>;
  };

  //체크된 아이템
  const [checkedItems, setCheckedItems] = useState([]);
  //체크용 id 리스트
  const [idList, setIdList] = useState([]);

  // membershipList 상태
  const [membershipList, setMembershipList] = useState([0, 1, 3, 6]);
  // membershipList 체크박스 상태
  const [membershipFilterChecked, setMembershipFilterChecked] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [paymentList, setPaymentList] = useState([
    'none',
    // 'iamport',
    'kakao',
    // 'innopay',
    'inicis',
    'nopassbook',
  ]);
  const [paymentFilterChecked, setPaymentFilterChecked] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [membersOrderBy, setMembersOrderBy] = useState([
    { key: 'create_at', order: 'DESC' },
  ]);

  const [selected, setSelected] = useState('default');

  const [keyword, setKeyword] = useState('');
  const [searchList, setSearchList] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSelect = e => {
    setSelected(e.target.value);
  };

  const reset = () => {
    setStartDate(new Date('January 1, 2021'));
    setMembershipFilterChecked([false, false, false, false, false]);
    setMembershipList([0, 1, 3, 6]);
    setPaymentFilterChecked([false, false, false, false, false]);
    setPaymentList([
      'none',
      // 'iamport',
      'kakao',
      // 'innopay',
      'inicis',
      'nopassbook',
    ]);
    setSelected('default');
    setKeyword('');
  };

  const fetchUsersData = () => {
    const today = new Date();
    const formatNext = dayjs(today).add(1, 'day').format('YYYY-MM-DD');

    const body = {
      page: currentPage,
      count: 30,
      membershipList,
      serviceList: paymentList,
      keyword,
      periodOption:
        selected !== 'default'
          ? {
              option: selected,
              startDate: startDate,
              endDate: formatNext,
            }
          : undefined,
      orderBy: membersOrderBy,
    };

    requestUserList({ body, setLoading, setSearchList, setIdList, setMaxPage });
  };

  useEffect(() => {
    fetchUsersData();
  }, [currentPage, keyword, membershipList, paymentList, membersOrderBy]);

  return (
    <>
      <Box className="MemberContainer">
        <Box
          maxW="1300px"
          m="0 auto"
          bg="#fff"
          padding="36px"
          boxShadow="rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"
        >
          <MemberCheckbox
            membershipFilterChecked={membershipFilterChecked}
            setMembershipFilterChecked={setMembershipFilterChecked}
            setMembershipList={setMembershipList}
            membershipList={membershipList}
          />

          <PaymentCheckbox
            paymentFilterChecked={paymentFilterChecked}
            setPaymentFilterChecked={setPaymentFilterChecked}
            setPaymentList={setPaymentList}
            paymentList={paymentList}
          />

          <Flex
            w="100%"
            alignItems="center"
            gridGap={15}
            direction={{ base: 'column', sm: 'row' }}
            mt={{ base: '15px', sm: '0' }}
          >
            <Select
              className="selectOption"
              defaultValue={selected}
              onChange={handleSelect}
              maxW="300px"
            >
              <option value="default" disabled>
                날짜 기준을 먼저 선택해주세요
              </option>
              <option value="create_at">가입 일자</option>
              <option value="login_at">로그인 일자</option>
            </Select>
            <DatePicker
              className="DatePickerStyle"
              dateFormat="yyyy/MM/dd"
              selected={startDate}
              maxDate={new Date()}
              onChange={date => setStartDate(date)}
              locale={ko}
              renderDayContents={renderDayContents}
            />
          </Flex>
          <SearchMembersInput
            keyword={keyword}
            setKeyword={setKeyword}
            fetchUsersData={fetchUsersData}
          />

          <Flex justifyContent="flex-end">
            <ResetBtn onClick={reset}>필터 초기화</ResetBtn>
            <CSVLink
              headers={headers}
              data={searchList}
              filename={'회원_현황'}
              download="회원_현황.csv"
              onClick={() => !!window.confirm('다운로드 하시겠습니까?')}
            >
              <ExcelDownBtn>CSV 내려받기</ExcelDownBtn>
            </CSVLink>
          </Flex>

          <Divider height={'10px'} my="10px" />
          <Box>
            <SelectedCheckBox MembershipList={membershipList} />
          </Box>
        </Box>
      </Box>
      <TableContainerBox
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
        loading={loading}
        idList={idList}
        searchList={searchList}
        maxPage={maxPage}
        membersOrderBy={membersOrderBy}
        setMembersOrderBy={setMembersOrderBy}
      />
    </>
  );
};

export default Members;
