import { useState, useLayoutEffect, Suspense, lazy, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Skeleton, useDisclosure, useToast } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import 'react-datepicker/dist/react-datepicker.css';
import AutoSerialDetail from './AutoSerialDetail';
import { Pagination } from 'Common/Pagination';
import SearchSerialBox from 'Components/Management/Serials/SearchSerialBox';
import dayjs from 'dayjs';
import IsDeleteModal from 'Common/IsDeleteModal';
import styled from 'styled-components';
import { deleteAutoGPTCoupon, getAutoGPTCouponList } from 'api/auth/autogpt';
import UseResponseToast from 'Hook/UseResponseToast';
import { useRecoilState } from 'recoil';
import { adminState } from 'config/Recoil';

const SerialTable = lazy(() =>
  import('Components/Management/Serials/SerialTable')
);

const NoDataBox = styled(Flex)`
  justify-content: center;
  align-items: center;

  background-color: #fff;
  height: 200px;
  margin-top: 30px;
  max-width: 1400px;
  margin: 30px auto 0;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;

  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
`;
const GetAutoerial = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const responseToast = UseResponseToast();
  const [admin, setAdmin] = useRecoilState(adminState);
  const navigate = useNavigate();
  const [uid, setUid] = useState('');
  const [loading, setLoading] = useState(false);

  //체크된 아이템
  const [checkedItems, setCheckedItems] = useState([]);
  //체크용 id 리스트
  const [uidList, setUidList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrent] = useState(1); //현재 페이지;
  const [searchBody, setSearchBody] = useState({
    campaign_name: '', //캠페인명
    coupon_uid: '', // 쿠폰 uid
    is_used: '', // 시용 여부
    user: '', // 유저 이메일
  });
  const [plan, setPlan] = useState(0);
  const [startDate, setStartDate] = useState(new Date('July 1, 2022'));
  const [endDate, setEndDate] = useState(new Date());

  const offset = (currentPage - 1) * 30;

  const { campaign_name, coupon_uid, is_used, user } = searchBody;

  let initial = {
    page: currentPage,
    count: 1000,
    campaign_name: campaign_name,
    coupon_uid: coupon_uid,
    is_used: is_used,
    user: user,
    startDate: startDate,
    endDate: dayjs(endDate).add('1', 'day').$d,
  };

  let withPlan = {
    page: currentPage,
    count: 1000,
    campaign_name: campaign_name,
    coupon_uid: coupon_uid,
    is_used: is_used,
    plan: plan,
    user: user,
    startDate: startDate,
    endDate: dayjs(endDate).add('1', 'day').$d,
  };
  const [data, setData] = useState('');

  const HandleSearchBody = e => {
    setSearchBody({ ...searchBody, [e.target.id]: e.target.value });
  };
  const HandleDetailModal = UID => {
    setModalOpen(!modalOpen);
    setUid(UID);
  };

  //시리얼 넘버 전부 체크
  const CheckAll = e => {
    setCheckedItems(e.target.checked ? uidList : []);
  };

  const CheckEach = (e, uid) => {
    //체크 되면 CheckedItems에 해당 uid 넣기
    if (e.target.checked) {
      setCheckedItems([...checkedItems, uid]);
    } else {
      setCheckedItems(checkedItems.filter(item => item !== uid));
    }
  };

  const DeleteSerial = async () => {
    //uid 전부 넣은 리스트에서 checkeditem에 있는 uid만 가져오기
    const checkedArray = uidList.filter(item => checkedItems.includes(item));

    if (checkedArray.length === 0) {
      onClose();
      toast({
        title: '선택한 유저가 없어요!',
        description: '삭제할 유저를 선택해주세요.',
        position: 'top-right',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    }

    if (checkedArray.length <= 1) {
      const { error } = await deleteAutoGPTCoupon(checkedArray[0]);
      if (error) {
        return;
      }
      navigate(0);
      return;
    }

    Promise.all(
      checkedArray
        .map(async param => {
          return deleteAutoGPTCoupon(param);
        })
        .then(res => {
          navigate(0);
        })
        .catch(error => {
          toast({
            title: 'Fail',
            description: 'autogpt 쿠폰 삭제 실패',
            position: 'top-right',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        })
    );
  };

  const Reset = () => {
    setSearchBody({
      ...searchBody,
      campaign_name: '',
      coupon_uid: '',
      is_used: '',
      user: '',
    });
    setPlan(0);
    setStartDate(new Date('July 1, 2022'));
    setEndDate(new Date());
  };

  const SearchSerial = async () => {
    setLoading(true);

    const targetConfig = plan > 0 ? withPlan : initial;

    const { statusCode, response, title, description, error } =
      await getAutoGPTCouponList(targetConfig);

    responseToast({
      title,
      description,
      statusCode,
    });

    if (error) {
      return;
    }

    const data = response.data.data;

    const orderList = data.sort(
      (a, b) => new Date(b.create_at) - new Date(a.create_at)
    );

    let uidList = [];
    const uids = orderList.map((item, i) => (uidList[i] = item.coupon_uid));
    setUidList(uids);
    setData(orderList);

    setLoading(false);

    responseToast({ statusCode, title, description });
  };

  useEffect(() => {
    // IIFE를 사용하여 비동기 로직을 처리합니다.
    SearchSerial();
  }, []);

  return (
    <>
      <Box className="MemberContainer">
        <SearchSerialBox
          campaign_name={campaign_name}
          HandleSearchBody={HandleSearchBody}
          coupon_uid={coupon_uid}
          plan={plan}
          setPlan={setPlan}
          user={user}
          is_used={is_used}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          SearchSerial={SearchSerial}
          Reset={Reset}
        />
        {/* 시리얼 결과 테이블 */}
        {data.length === 0 && <NoDataBox>결과가 없습니다! 🤭</NoDataBox>}
        {data.length !== 0 && (
          <>
            <Box className="TableContainer">
              <Flex justify="flex-end" mb={25} spacing="15px">
                <DeleteIcon
                  onClick={onOpen}
                  w={5}
                  h={5}
                  style={{ cursor: 'pointer' }}
                />
              </Flex>
              <Suspense>
                <Skeleton h="100%" isLoaded={!loading}>
                  <SerialTable
                    data={data}
                    uidList={uidList}
                    offset={offset}
                    checkedItems={checkedItems}
                    CheckAll={CheckAll}
                    CheckEach={CheckEach}
                    HandleDetailModal={HandleDetailModal}
                  />
                </Skeleton>
              </Suspense>
            </Box>
            <Pagination
              total={data.length}
              currentPage={currentPage}
              setCurrent={setCurrent}
            />
          </>
        )}
      </Box>
      <AutoSerialDetail
        admin={admin}
        UID={uid}
        isOpen={modalOpen}
        onClose={HandleDetailModal}
      />
      <IsDeleteModal isOpen={isOpen} onClose={onClose} Delete={DeleteSerial} />
    </>
  );
};

export default GetAutoerial;
