import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Button, useDisclosure, useToast } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Back, DeleteCancelBtn, ModifyBtn } from 'styles/ComponentStyle';
import IsDeleteModal from 'Common/IsDeleteModal';
import { deletePrompt, getPrompt, uploadPrompt } from 'api/auth/prompt';
import UseResponseToast from 'Hook/UseResponseToast';
import { useRecoilState } from 'recoil';
import { adminState } from 'config/Recoil';

const Container = styled(Box)`
  background-color: #fff;
  border: 1px solid #444;
  padding: 30px 20px;
`;

const TextareaBox = styled(Box)`
  width: 100%;
  border: 1px solid #444;
`;

const ButtonBox = styled(Box)`
  width: 100%;
  background-color: #f9f9f9;
  padding: 10px;
`;

const PromptDetail = () => {
  const [admin, setAdmin] = useRecoilState(adminState);
  const toast = useToast();
  const responseToast = UseResponseToast();
  const navigate = useNavigate();
  const cursor = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();

  const [isModify, setModify] = useState(false);
  const [prompts, setprompt] = useState({
    admin_uid: '',
    frequency_penalty: '',
    max_tokens: '',
    model: '',
    name: '',
    presence_penalty: '',
    temperature: 0,
    text: '',
  });

  const [stop_sequence, setStop] = useState([]);

  const {
    admin_uid,
    frequency_penalty,
    max_tokens,
    model,
    name,
    presence_penalty,
    temperature,
    text,
  } = prompts;

  const addText = () => {
    let textValue = cursor.current.value;
    const cursorStart = cursor.current.selectionStart;

    const startValue = textValue.substring(cursorStart, 0);
    const endValue = textValue.substring(cursorStart);

    cursor.current.value = startValue + `{}` + endValue;
  };

  const DeletePrompt = async () => {
    const { statusCode, response, title, description, error } =
      await deletePrompt(id);
    responseToast({
      statusCode,
      title,
      description,
    });

    if (error) {
      return;
    }

    navigate('/prompts');
  };

  const ModifyPrompt = async () => {
    const isBlank = Object.values(prompts);

    if (isBlank.includes('') === true) {
      toast({
        title: '빈 칸이 남아 있습니다.',
        description: '빈 칸을 모두 채워주세요!',
        position: 'top-right',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { statusCode, response, title, description, error } =
      await uploadPrompt({
        promptUid: id,
        name: name,
        text: text,
        max_tokens: Number(max_tokens),
        temperature: Number(temperature),
        frequency_penalty: Number(frequency_penalty),
        presence_penalty: Number(presence_penalty),
        stop_sequence: [stop_sequence],
        // stop_sequence: stop_sequence.length > 2 ? [stop_sequence.split(',')] : [stop_sequence],
        model: model,
        is_aivs: false,
        is_get_image: false,
      });

    responseToast({ statusCode, title, description });

    if (error) {
      return;
    }

    navigate('/prompts');
  };

  const SettingModify = () => {
    setModify(true);
  };

  const ChangePrompt = e => {
    setprompt({ ...prompts, [e.target.name]: e.target.value });
  };

  const ChangeStop = e => {
    setStop(e.target.value);
  };

  const fetchData = async () => {
    const { response, title, description, statusCode, error } = await getPrompt(
      id
    );
    responseToast({ title, description, statusCode });

    if (error) {
      return;
    }

    const prompt = response.data.data;

    setprompt({
      ...prompt,
      admin_uid: prompt.admin_uid,
      create_at: prompt.create_at,
      frequency_penalty: prompt.frequency_penalty,
      max_tokens: prompt.max_tokens,
      model: prompt.model,
      name: prompt.name,
      presence_penalty: prompt.presence_penalty,
      temperature: prompt.temperature,
      text: prompt.text,
      update_at: prompt.update_at,
    });
    setStop(prompt.stop_sequence);
  };

  useEffect(() => {
    fetchData(); // 토큰이 유효한 경우, 데이터 가져오기 로직 실행
  }, []);

  return (
    <>
      <Box padding="48px" maxW="1200px" m="0 auto">
        <Container>
          <Flex w="100%" direction={'column'} className="makePromtLabelInput">
            <label htmlFor="name">서비스명</label>
            <input
              type="text"
              id="name"
              name="name"
              disabled={isModify ? false : true}
              value={name}
              onChange={ChangePrompt}
            />
          </Flex>
          <Flex w="100%" direction={'column'} className="makePromtLabelInput">
            <label htmlFor="writer">작성자</label>
            <input
              type="text"
              id="writer"
              name="admin_uid"
              disabled={isModify ? false : true}
              value={admin_uid}
              onChange={ChangePrompt}
            />
          </Flex>
          <Flex w="100%" direction={'column'} className="makePromtLabelInput">
            <label htmlFor="engine">engine</label>
            <input
              type="text"
              htmlFor="engine"
              name="model"
              disabled={isModify ? false : true}
              value={model}
              onChange={ChangePrompt}
            />
          </Flex>
          <Flex
            w="100%"
            direction={'column'}
            className="makePromtLabelTextarea"
          >
            <label htmlFor="prompt">prompt </label>
            <TextareaBox>
              <textarea
                id="prompt"
                name="text"
                disabled={isModify ? false : true}
                value={text}
                onChange={ChangePrompt}
                ref={cursor}
              />
              {isModify && (
                <ButtonBox>
                  <Button onClick={addText}>사용자 입력부분</Button>
                </ButtonBox>
              )}
            </TextareaBox>
          </Flex>
          <Flex
            w="100%"
            direction={'column'}
            className="makePromtLabelTextarea"
          >
            <label htmlFor="stop_sequence">stop_sequence</label>
            <textarea
              id="stop_sequence"
              name="stop_sequence"
              disabled={isModify ? false : true}
              value={stop_sequence}
              onChange={ChangeStop}
            />
          </Flex>
          <Flex
            w="100%"
            gridGap={{ base: '0px', lg: '20px' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <Flex
              w="100%"
              direction={'column'}
              className="makePromtLabelInputTwo"
            >
              <label htmlFor="temperature">temperature</label>
              <input
                type="number"
                step="0.1"
                min="0"
                id="temperature"
                name="temperature"
                disabled={isModify ? false : true}
                value={temperature}
                onChange={ChangePrompt}
              />
            </Flex>
            <Flex
              w="100%"
              direction={'column'}
              className="makePromtLabelInputTwo"
            >
              <label htmlFor="max_tokens">max_tokens</label>
              <input
                type="number"
                id="max_tokens"
                name="max_tokens"
                disabled={isModify ? false : true}
                value={max_tokens}
                onChange={ChangePrompt}
              />
            </Flex>
          </Flex>

          <Flex
            w="100%"
            gridGap={{ base: '0px', lg: '20px' }}
            direction={{ base: 'column', lg: 'row' }}
          >
            <Flex
              w="100%"
              direction={'column'}
              className="makePromtLabelInputTwo"
            >
              <label htmlFor="presence_penalty">presence_penalty</label>
              <input
                type="number"
                id="presence_penalty"
                name="presence_penalty"
                disabled={isModify ? false : true}
                value={presence_penalty}
                onChange={ChangePrompt}
              />
            </Flex>
            <Flex
              w="100%"
              direction={'column'}
              className="makePromtLabelInputTwo"
            >
              <label htmlFor="frequency_penalty">frequency_penalty</label>
              <input
                type="number"
                id="frequency_penalty"
                name="frequency_penalty"
                disabled={isModify ? false : true}
                value={frequency_penalty}
                onChange={ChangePrompt}
              />
            </Flex>
          </Flex>

          <Flex
            align={'center'}
            justify={{ base: 'center', sm: 'flex-end' }}
            mt="15px"
            gridGap={'15px'}
          >
            <ModifyBtn onClick={isModify ? ModifyPrompt : SettingModify}>
              {isModify ? '수정완료' : '수정'}
            </ModifyBtn>
            <DeleteCancelBtn onClick={onOpen}>삭제</DeleteCancelBtn>
          </Flex>
        </Container>
        <Box textAlign={'right'} mt="15px">
          <Link to="/prompts">
            <Back>목록</Back>
          </Link>
        </Box>
      </Box>
      <IsDeleteModal isOpen={isOpen} onClose={onClose} Delete={DeletePrompt} />
    </>
  );
};

export default PromptDetail;
