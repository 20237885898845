import React from 'react';
import { PayMethodBtn } from 'styles/ComponentStyle';
import { Wrap, WrapItem } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const SelectedCheckBox = ({ MembershipList }) => {
  return (
    <Wrap spacing={'10px'}>
      {MembershipList?.map(item => (
        <WrapItem key={item}>
          <PayMethodBtn
            bg="#fff"
            borderColor="#474554"
            font="#474554"
            id={item}
          >
            <CheckIcon w={2} h={2} />
            {item === '0' ? '구독 안함' : `${item}개월`}
          </PayMethodBtn>
        </WrapItem>
      ))}
    </Wrap>
  );
};

export default SelectedCheckBox;
