import React, { Suspense, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Skeleton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import { Pagination } from 'Common/Pagination';
import IsDeleteModal from 'Common/IsDeleteModal';
import AddGroupModal from './AddGroupModal';
import GroupListTable from './GroupListTable';
import { deleteGroupList, getGroupByName, getGroups } from 'api/auth/group';
import UseResponseToast from 'Hook/UseResponseToast';

const GroupsList = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const responseToast = UseResponseToast();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrent] = useState(1); //현재 페이지;
  const [maxPage, setMaxPage] = useState(0);

  //체크된 아이템
  const [checkedItems, setCheckedItems] = useState([]);
  //체크용 id 리스트
  const [idList, setIdList] = useState([]);

  const [keyword, setKeyword] = useState('');
  const [searchList, setSearchList] = useState('');
  const [isExistEmail, setIsExistEmail] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);

  const openAddGroupModal = () => {
    setIsAddGroupModalOpen(true);
    setIsExistEmail(null);
  };

  const DeleteGroups = async () => {
    const checkedArray = idList.filter(item => checkedItems.includes(item));

    if (checkedArray.length === 0) {
      toast({
        title: '선택한 유저가 없어요!',
        description: '삭제할 유저를 선택해주세요.',
        position: 'top-right',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { statusCode, title, description, error } = await deleteGroupList(
      checkedArray
    );

    responseToast({ statusCode, title, description });

    if (error) {
      return;
    }

    setSearchList(
      searchList.filter(group => !checkedArray.includes(group.uid))
    );

    setIdList(idList.filter(id => !checkedArray.includes(id)));

    onClose();
  };

  const searchGroupList = async e => {
    e.preventDefault();
    setCurrent(1);
    // 그룹 리스트 검색
    setLoading(true);
    const {
      statusCode,
      response: getGroupsByNameResponse,
      title,
      description,
      error,
    } = await getGroupByName(keyword || null);
    setLoading(false);

    responseToast({
      statusCode,
      title,
      description,
    });

    if (error) {
      return;
    }

    setSearchList(getGroupsByNameResponse.data.data.groups);
    setIdList(getGroupsByNameResponse.data.data.groups.map(group => group.uid));
  };

  useEffect(() => {
    // 토큰 상태 검증 및 갱신 로직

    const fetchData = async () => {
      setLoading(true);
      const { statusCode, response, title, description, error } =
        await getGroups({
          page: 1,
          limit: 50,
        });
      setLoading(false);

      responseToast({ title, description, statusCode });

      if (error) {
        return;
      }

      setSearchList(response.data.data.groups);
      const orderList = response.data.data.groups.sort(
        (a, b) => new Date(b.create_at) - new Date(a.create_at)
      );
      setSearchList(orderList);

      setIdList(orderList.map(groupInfo => groupInfo.uid));
      setMaxPage(response.data.config.maxPage);
    };

    fetchData();
  }, []);

  return (
    <>
      <Box className="MemberContainer">
        <h1
          style={{
            maxWidth: '1300px',
            margin: '0 auto',
            paddingBottom: '20px',
            fontSize: '20px',
          }}
        >
          그룹 관리
        </h1>
        <Box
          maxW="1300px"
          m="0 auto"
          bg="#fff"
          padding="36px"
          boxShadow="rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"
        >
          <Box margin="15px 0">
            <form onSubmit={searchGroupList}>
              <Flex
                className="SearchFlex"
                alignItems="center"
                justify="space-between"
                direction={{ base: 'column', sm: 'row' }}
              >
                <input
                  type={'text'}
                  placeholder="검색할 키워드를 입력해주세요"
                  value={keyword || ''}
                  onChange={e => {
                    e.stopPropagation();
                    setKeyword(e.target.value);
                  }}
                />
                <Button type="submit">
                  <SearchIcon />
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
      <Box className="TableContainer">
        <Flex justify="flex-end" mb={25} spacing="15px">
          <AddIcon
            onClick={openAddGroupModal}
            w={5}
            h={5}
            style={{ cursor: 'pointer', marginRight: '15px' }}
          />
          <DeleteIcon
            onClick={onOpen}
            w={5}
            h={5}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
        <Box
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': {
              //스크롤바 전체영역
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              //스크롤바 움직이는 영역
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              //스크롤
              backgroundColor: '#E6F4F1',
              borderRadius: '5px',
            },
          }}
        >
          <Suspense>
            <Skeleton h="100%" isLoaded={!loading}>
              <GroupListTable
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                idList={idList}
                searchList={searchList}
              />
            </Skeleton>
          </Suspense>
        </Box>
        <Pagination
          total={maxPage}
          setCurrent={setCurrent}
          currentPage={currentPage}
          toast={toast}
        />
      </Box>
      <IsDeleteModal isOpen={isOpen} onClose={onClose} Delete={DeleteGroups} />
      <AddGroupModal
        isAddGroupModalOpen={isAddGroupModalOpen}
        setIsAddGroupModalOpen={setIsAddGroupModalOpen}
        setIsExistEmail={setIsExistEmail}
        isExistEmail={isExistEmail}
        setSearchList={setSearchList}
        searchList={searchList}
        setIdList={setIdList}
      />
    </>
  );
};

export default GroupsList;
