import {
  Box,
  Button,
  Flex,
  Skeleton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddIcon, DeleteIcon, SearchIcon } from '@chakra-ui/icons';
import { Pagination } from 'Common/Pagination';
import IsDeleteModal from 'Common/IsDeleteModal';
import AddGroupMemberModal from './AddGroupMemberModal';
import GroupDetailTable from './GroupDetailTable';
import {
  deleteUserListFromGroup,
  getByGroupUid,
  searchUserListByGroupUid,
} from 'api/auth/group';
import UseResponseToast from 'Hook/UseResponseToast';

const GroupDetail = () => {
  const toast = useToast();
  const params = useParams();
  const responseToast = UseResponseToast();

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrent] = useState(1); // 현재 페이지;
  const [maxPage, setMaxPage] = useState(0);

  // 체크된 아이템
  const [checkedItems, setCheckedItems] = useState([]);
  // 체크용 id 리스트
  const [idList, setIdList] = useState([]);
  // membershipList 기본

  const [keyword, setKeyword] = useState('');
  const [searchList, setSearchList] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isAddGroupMemberModal, setIsAddGroupMemberModal] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({});

  const openAddGroupMemberModal = () => {
    setIsAddGroupMemberModal(true);
  };

  const DeleteGroups = async () => {
    setLoading(true);
    const checkedArray = idList.filter(item => checkedItems.includes(item));

    if (checkedArray.length <= 0) {
      onClose();
      toast({
        title: '선택한 유저가 없어요!',
        description: '삭제할 유저를 선택해주세요.',
        position: 'top-right',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // 삭제 API 호출

    const { response, error, title, description, statusCode } =
      await deleteUserListFromGroup(currentGroup.uid);
    setLoading(false);
    responseToast({ title, description, statusCode });

    if (error) {
      return;
    }

    setSearchList(
      searchList.filter(member => !checkedArray.includes(member.user_uid))
    );

    setIdList(idList.filter(id => !checkedArray.includes(id)));
    onClose();
  };

  const searchGroupUserList = async e => {
    e.preventDefault();
    setCurrent(1);
    setLoading(true);

    const {
      statusCode,
      response: getSearchGroupMemberResponse,
      title,
      description,
      error,
    } = await searchUserListByGroupUid({
      group_uid: currentGroup.uid,
      name: keyword || null,
    });

    responseToast({
      title,
      description,
      statusCode,
    });

    if (error) {
      return;
    }

    setSearchList(getSearchGroupMemberResponse.data.data.getUserList);
    setIdList(
      getSearchGroupMemberResponse.data.data.getUserList.map(
        user => user.user_uid
      )
    );
    setLoading(false);
  };

  useEffect(() => {
    // 토큰 상태 검증 및 갱신 로직
    const requestGroupDetail = async () => {
      const {
        statusCode,
        response: getGroupMemberListResponse,
        title,
        description,
        error,
      } = await getByGroupUid(params.uid);

      responseToast({
        title,
        description,
        statusCode,
      });

      if (error) {
        return;
      }

      const newIdList = getGroupMemberListResponse.data.data.getUserList.map(
        user => user.user_uid
      );
      setSearchList(getGroupMemberListResponse.data.data.getUserList);
      setIdList(newIdList);
      setCurrentGroup(getGroupMemberListResponse.data.data.group);
    };

    requestGroupDetail();
  }, []);

  return (
    <>
      <Box className="MemberContainer">
        <h1
          style={{
            maxWidth: '1300px',
            margin: '0 auto',
            paddingBottom: '20px',
            fontSize: '20px',
          }}
        >
          {currentGroup?.admin_user_email} 님의 {currentGroup.name} 그룹
        </h1>
        <Box
          maxW="1300px"
          m="0 auto"
          bg="#fff"
          padding="36px"
          boxShadow="rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"
        >
          <Box margin="15px 0">
            <form onSubmit={searchGroupUserList}>
              <Flex
                className="SearchFlex"
                alignItems="center"
                justify="space-between"
                direction={{ base: 'column', sm: 'row' }}
              >
                <input
                  type={'text'}
                  placeholder="검색할 키워드를 입력해주세요"
                  value={keyword || ''}
                  onChange={e => {
                    e.stopPropagation();
                    setKeyword(e.target.value);
                  }}
                />
                <Button type="submit">
                  <SearchIcon />
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
      <Box className="TableContainer">
        <Flex justify="flex-end" mb={25} spacing="15px">
          <AddIcon
            onClick={openAddGroupMemberModal}
            w={5}
            h={5}
            style={{ cursor: 'pointer', marginRight: '15px' }}
          />
          <DeleteIcon
            onClick={onOpen}
            w={5}
            h={5}
            style={{ cursor: 'pointer' }}
          />
        </Flex>
        <Box
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': {
              //스크롤바 전체영역
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              //스크롤바 움직이는 영역
              backgroundColor: '#fff',
            },
            '&::-webkit-scrollbar-thumb': {
              //스크롤
              backgroundColor: '#E6F4F1',
              borderRadius: '5px',
            },
          }}
        >
          <Suspense>
            <Skeleton h="100%" isLoaded={!loading}>
              <GroupDetailTable
                setCheckedItems={setCheckedItems}
                checkedItems={checkedItems}
                idList={idList}
                searchList={searchList}
              />
            </Skeleton>
          </Suspense>
        </Box>
        <Pagination
          total={maxPage}
          setCurrent={setCurrent}
          currentPage={currentPage}
          toast={toast}
        />
      </Box>
      <IsDeleteModal isOpen={isOpen} onClose={onClose} Delete={DeleteGroups} />
      <AddGroupMemberModal
        setLoading={setLoading}
        isAddGroupMemberModal={isAddGroupMemberModal}
        setIsAddGroupMemberModal={setIsAddGroupMemberModal}
        currentGroup={currentGroup}
        searchList={searchList}
        setSearchList={setSearchList}
        setIdList={setIdList}
      />
    </>
  );
};

export default GroupDetail;
