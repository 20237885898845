import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';

import InfoBox from './InfoBox';
import { getProfile } from 'api/auth/user';
import UseResponseToast from 'Hook/UseResponseToast';

const MemInfo = () => {
  const { id } = useParams();
  const toast = useToast();
  const responseToast = UseResponseToast();

  const [Data, setData] = useState({
    membership: '',
    user: '',
  });

  const { membership, user } = Data;

  const [selectedMembership, setSelected] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [passbook, setPassbook] = useState(false);

  const fetchData = async e => {
    const { statusCode, response, title, description, error } =
      await getProfile(id);

    responseToast({ title, description, statusCode });

    if (error) {
      return;
    }

    const userData = response.data.data;

    setStartDate(new Date(userData.membership?.start_date));
    setEndDate(new Date(userData.membership?.next_date));

    if (userData.membership.bill_service === 'nopassbook') {
      setPassbook(true);
    }

    setData({
      ...Data,
      membership: userData.membership,
      user: userData.user,
    });
    setSelected(userData.membership_legacy.current);
  };

  useEffect(() => {
    fetchData(); // 토큰이 유효하면 데이터 가져오기
  }, []);

  return (
    <>
      <InfoBox user={user} membership={membership} toast={toast} />
    </>
  );
};

export default MemInfo;
