import getAuthAxios from 'utill/getAuthAxios';

const authAxios = getAuthAxios();

export const getInquiry = ({ page, count, category, content, search }) => {
  return authAxios.post(`/inquiry/list/search`, {
    page,
    count,
    category, // optional ['이용 문의', '오류 신고', '서비스 제안', '환불', '탈퇴', '기타']
    content, // optional
    search, // optional
  });
};

export const updateInquiry = ({ inquiry_uid, status }) => {
  return authAxios.put(`/inquiry/${inquiry_uid}?status=${status}`);
};
