import getAuthAxios from 'utill/getAuthAxios';

const authAxios = getAuthAxios();

export const createCoupon = ({ campaign_name, desc, plan, date, many }) => {
  return authAxios.post(`/coupon`, {
    campaign_name, // 캠페인명
    desc, // 상세설명
    plan, // 개월수, 0으로 설정하면 date값을 추가해야 함
    date, // 쿠폰이 추가해주는 날짜. plan이 0일때에만 사용
    many, // 개수
  });
};

export const updateCoupone = ({ coupon_uid, campaign_name, desc, plan }) => {
  return authAxios.put(`/coupon?coupon_uid=${coupon_uid}`, {
    campaign_name, //캠페인명
    desc, //상세설명
    plan, //개월수
  });
};

export const deleteCoupon = coupon_uid => {
  return authAxios.delete(`/coupon?coupon_uid=${coupon_uid}`);
};

export const getCouponDetail = coupon_uid => {
  return authAxios.get(`/coupon/detail?coupon_uid=${coupon_uid}`);
};

export const getCouponList = ({
  page,
  count,
  campaign_name,
  plan,
  coupon_uid,
  is_used,
  user,
  startDate,
  endDate,
}) => {
  return authAxios.post(`/coupon/list`, {
    page,
    count,
    //아래 사항들은 전부 옵셔녈
    campaign_name, //캠페인명
    plan, //개월수
    coupon_uid, // 쿠폰 uid
    is_used, // 시용 여부
    user, // 유저 이메일
    startDate, // 쿠폰 생성일 범위
    endDate, // 쿠폰 생성일
  });
};
