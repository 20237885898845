import { Box, Flex, useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import {
  ExcelDownBtn,
  ExtraBtn,
  TbodyStyle,
  TrStyle,
} from 'styles/ComponentStyle';

const TrThStyle = styled.tr`
  text-align: center;

  .Custom-1 {
    width: 80px;
  }

  .Custom-2 {
    width: 300px;
  }

  .Custom-3,
  .Custom-4,
  .Custom-7 {
    width: 200px;
  }

  .Custom-5,
  .Custom-6,
  .Custom-8 {
    width: 100px;
  }
`;

const GeneratedSerialResult = ({ data }) => {
  const toast = useToast();
  const [copied, setCopied] = useState(false);

  let num = 0;

  const headers = [
    { label: '시리얼 넘버', key: 'coupon_uid' },
    { label: '캠페인명', key: 'campaign_name' },
    { label: '생성일자', key: 'create_at' },
    { label: '혜택구분', key: 'plan' },
  ];

  const OnCopied = () => {
    setCopied(true);
    toast({
      title: '성공!',
      description: `시리얼 넘버를 복사했습니다!`,
      position: 'top-right',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Box className="TableContainer">
      <Flex justify="flex-end" mb={25} spacing="15px">
        <CSVLink
          headers={headers}
          data={data}
          filename={'시리얼넘버'}
          download="시리얼넘버.csv"
          onClick={() => !!window.confirm('다운로드 하시겠습니까?')}
        >
          <ExcelDownBtn>CSV 내려받기</ExcelDownBtn>
        </CSVLink>
      </Flex>
      <Box
        overflowX="auto"
        css={{
          '&::-webkit-scrollbar': {
            //스크롤바 전체영역
            width: '5px',
          },
          '&::-webkit-scrollbar-track': {
            //스크롤바 움직이는 영역
            backgroundColor: '#fff',
          },
          '&::-webkit-scrollbar-thumb': {
            //스크롤
            backgroundColor: '#E6F4F1',
            borderRadius: '5px',
          },
        }}
      >
        <table className="MemberCustomTableStyle">
          <thead>
            <TrThStyle className="MemberCustom-tr MemberCustom-thead-tr">
              <th className="Custom-1">No</th>
              <th className="Custom-2">시리얼 넘버</th>
              <th className="Custom-3">캠페인명</th>
              <th className="Custom-4">생성일자</th>
              <th className="Custom-5">혜택구분</th>
              <th className="Custom-6">복사</th>
            </TrThStyle>
          </thead>
          <TbodyStyle>
            {data.map(item => (
              <TrStyle key={item.coupon_uid} className="MemberCustom-tr">
                <td>{(num = num + 1)}</td>
                <td>{item.coupon_uid}</td>
                <td>{item.campaign_name}</td>
                <td>{dayjs(item.create_at).format('YYYY-MM-DD')}</td>
                <td>{item.plan}개월</td>
                <td>
                  <CopyToClipboard text={item.coupon_uid} onCopy={OnCopied}>
                    <ExtraBtn colorScheme="blue">Copy</ExtraBtn>
                  </CopyToClipboard>
                </td>
              </TrStyle>
            ))}
          </TbodyStyle>
        </table>
      </Box>
    </Box>
  );
};

export default GeneratedSerialResult;
