import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Heading, Flex, Button } from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { InfoDetailBox } from 'styles/ComponentStyle';
import RemoveMembershipBox from './RemoveMembershipBox';
import AddMembershipBox from './AddMembershipBox';
import TokenInputModal from './TokenInputModal';
import UserAutoGPTInfo from './UserAutoGPTInfo';
import { getUserAutogptPlan } from 'api/auth/plan';
import UseResponseToast from 'Hook/UseResponseToast';
import { useRecoilState } from 'recoil';
import { adminState } from 'config/Recoil';

const InfoBox = ({ user, membership }) => {
  const toastResponse = UseResponseToast();
  const { id } = useParams();
  const [admin, setAdmin] = useRecoilState(adminState);

  const navigate = useNavigate();

  const [tokenInput, setTokenInput] = useState(0);

  const [tokenInputModalInfo, setTokenInputModalInfo] = useState({
    open: false,
    target: '',
  });

  const [initialAuto, setInitialAuto] = useState('');

  const onOpenTokenModal = target => () => {
    setTokenInputModalInfo({
      open: true,
      target,
    });
  };

  const fetchAutoData = async () => {
    const { statusCode, response, title, description, error } =
      await getUserAutogptPlan(id);

    toastResponse({
      statusCode,
      title,
      description,
    });

    if (error) {
      return;
    }
    setInitialAuto(response.data.data);
  };

  const movePrevpage = () => {
    navigate(-1);
  };

  useEffect(() => {
    fetchAutoData();
  }, [admin.token]);

  return (
    <Box className="InfoPayContainer">
      <Box className="InfoContent">
        <Box padding={'15px'}>
          <Button onClick={movePrevpage}>
            <FaArrowLeft />
          </Button>
        </Box>
        <Heading as="h4" size="lg" margin="15px 0 15px 15px">
          💡 Info
        </Heading>
        <InfoDetailBox>
          <h4>이름</h4>
          <p>{user.name}</p>
        </InfoDetailBox>
        <InfoDetailBox>
          <h4>이메일</h4>
          <p>{user.email}</p>
        </InfoDetailBox>
        <InfoDetailBox>
          <h4>가입일자</h4>
          <p>{`${dayjs(user.create_at).format('YYYY-MM-DD')}`}</p>
        </InfoDetailBox>
        <RemoveMembershipBox user={user} membership={membership} />

        <InfoDetailBox>
          <h4>이용기간</h4>
          <p>
            {user.total_membership_expired_date
              ? dayjs(user.total_membership_expired_date).format(
                  'YYYY-MM-DD-hh:mm'
                )
              : '없음'}
          </p>
        </InfoDetailBox>

        <InfoDetailBox>
          <h4>최근 결제 수단</h4>
          <p>{membership.bill_service}</p>
        </InfoDetailBox>

        <AddMembershipBox user={user} />

        <InfoDetailBox>
          <h4>라이팅젤 토큰</h4>
          <Flex gap="10px">
            {membership.clova_token > 0
              ? `${membership.clova_token} token`
              : '토큰이 없습니다'}
            <Button
              size="sm"
              colorScheme="green"
              onClick={onOpenTokenModal('writinggel')}
            >
              수정
            </Button>
          </Flex>
        </InfoDetailBox>

        <InfoDetailBox>
          <h4>드로잉젤 토큰</h4>
          <Flex gap="10px">
            {user.draw_count > 0
              ? `${user.draw_count} token`
              : '토큰이 없습니다'}

            <Button
              size={'sm'}
              colorScheme="green"
              onClick={onOpenTokenModal('drawingel')}
            >
              수정
            </Button>
          </Flex>
        </InfoDetailBox>

        <InfoDetailBox>
          <h4>채팅젤 토큰</h4>
          <Flex gap="10px">
            {user.chat_token > 0
              ? `${user.chat_token} token`
              : '토큰이 없습니다'}

            <Button
              size={'sm'}
              colorScheme="green"
              onClick={onOpenTokenModal('chatingel')}
            >
              수정
            </Button>
          </Flex>
        </InfoDetailBox>

        <InfoDetailBox>
          <h4>비디오젤 토큰</h4>
          <Flex gap={'10px'}>
            {user.video_token}&nbsp;Token
            <Button
              size={'sm'}
              colorScheme="green"
              onClick={onOpenTokenModal('videogel')}
            >
              수정
            </Button>
          </Flex>
        </InfoDetailBox>

        {tokenInputModalInfo.open && (
          <TokenInputModal
            tokenModalInfo={tokenInputModalInfo}
            setTokenInputModalInfo={setTokenInputModalInfo}
            tokenInput={tokenInput}
            setTokenInput={setTokenInput}
          />
        )}

        <UserAutoGPTInfo initialAuto={initialAuto} />
      </Box>
    </Box>
  );
};

export default InfoBox;
