import getAuthAxios from 'utill/getAuthAxios';

const authAxios = getAuthAxios();

export const getUserList = ({
  page,
  count,
  membershipList,
  serviceList,
  keyword,
  periodOption,
  orderBy,
}) => {
  return authAxios.post(`/user/list/search`, {
    page,
    count,
    membershipList,
    serviceList,
    keyword,
    periodOption,
    orderBy,
    // orderBy: [
    //   { key: 'name', order: 'ASC' },
    //   { key: 'email', order: 'ASC' },
    //   { key: 'create_at', order: 'DESC' },
    //   { key: 'login_at', order: 'ASC' },
    // ],
  });
};

export const getUserDefault = () => {
  return authAxios.get(`/user`);
};

export const deleteUser = user_uid => {
  return authAxios.delete(`/user?user_uid=${user_uid}`);
};

export const getProfile = user_uid => {
  return authAxios.get(`/user/profile/${user_uid}`);
};
