import { Checkbox, Text } from '@chakra-ui/react';
import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

const MemberTableHead = ({
  membersOrderBy,
  setMembersOrderBy,
  setCheckedItems,
  checkedItems,
  idList,
}) => {
  const isNameColumnOrder = membersOrderBy.find(el => el.key === 'name')?.order;
  const isEmailColumnOrder = membersOrderBy.find(
    el => el.key === 'email'
  )?.order;
  const isCreateAtColumnOrder = membersOrderBy.find(
    el => el.key === 'create_at'
  )?.order;
  const isLoginColumnOrder = membersOrderBy.find(
    el => el.key === 'login_at'
  )?.order;
  const isIndeterminate = checkedItems.some(Boolean);

  const orderColumn =
    ({ isColumnOrder, targetKey }) =>
    () => {
      // 정렬요소가 없으면
      // -> 정렬요소추가(DESC로)
      if (!isColumnOrder) {
        setMembersOrderBy(prev => [{ key: targetKey, order: 'DESC' }]);
        return;
      }

      // 정렬요소가 ASC면
      // -> 정렬요소 DESC로 변경
      // 정렬요소가 DESC면
      // -> 정렬요소 ASC로 변경

      setMembersOrderBy([
        {
          key: targetKey,
          order: isColumnOrder === 'ASC' ? 'DESC' : 'ASC',
        },
      ]);
    };

  const checkAll = e => {
    setCheckedItems(e.target.checked ? idList : []);
  };
  return (
    <thead>
      <tr className="MemberCustom-tr MemberCustom-thead-tr">
        <th className="MemberCheckBox textCenter">
          <Checkbox
            name="all"
            value="all"
            colorScheme="blue"
            isChecked={checkedItems.length === idList.length}
            isIndeterminate={isIndeterminate}
            onChange={checkAll}
          />
        </th>
        <th className="MemberCustom-th1 textLeft">
          <Text
            display={'flex'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={orderColumn({
              isColumnOrder: isNameColumnOrder,
              targetKey: 'name',
            })}
          >
            회원명&nbsp;&nbsp;
            <FaChevronDown
              style={{
                opacity: isNameColumnOrder ? 1 : 0.1,
                rotate: isNameColumnOrder === 'ASC' ? '180deg' : '0',
              }}
            />
          </Text>
        </th>
        <th className="MemberCustom-th2 textLeft">
          <Text
            display={'flex'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={orderColumn({
              isColumnOrder: isEmailColumnOrder,
              targetKey: 'email',
            })}
          >
            이메일 주소&nbsp;&nbsp;
            <FaChevronDown
              cursor={'pointer'}
              style={{
                opacity: isEmailColumnOrder ? 1 : 0.1,
                rotate: isEmailColumnOrder === 'ASC' ? '180deg' : '0',
              }}
            />
          </Text>
        </th>
        <th className="MemberCustom-th3 textCenter">
          <Text
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={orderColumn({
              isColumnOrder: isCreateAtColumnOrder,
              targetKey: 'create_at',
            })}
          >
            가입일자&nbsp;&nbsp;
            <FaChevronDown
              cursor={'pointer'}
              style={{
                opacity: isCreateAtColumnOrder ? 1 : 0.1,
                rotate: isCreateAtColumnOrder === 'ASC' ? '180deg' : '0',
              }}
            />
          </Text>
        </th>
        <th className="MemberCustom-th4 textCenter">
          <Text
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={orderColumn({
              isColumnOrder: isLoginColumnOrder,
              targetKey: 'login_at',
            })}
          >
            최근 로그인&nbsp;&nbsp;
            <FaChevronDown
              cursor={'pointer'}
              style={{
                opacity: isLoginColumnOrder ? 1 : 0.1,
                rotate: isLoginColumnOrder === 'ASC' ? '180deg' : '0',
              }}
            />
          </Text>
        </th>
        <th className="MemberCustom-th5 textCenter">구독상품</th>
        <th className="MemberCustom-th7 textCenter">결제일자</th>
        <th className="MemberCustom-th8 textCenter">수정</th>
        <th className="MemberCustom-th9 textCenter">결제 기록</th>
      </tr>
    </thead>
  );
};

export default MemberTableHead;
