import { Button, Checkbox, Text } from '@chakra-ui/react';
import React from 'react';
import { updateCheckboxPropertyList } from 'utill/updateCheckboxPropertyList';

const PaymentCheckbox = ({
  paymentFilterChecked,
  setPaymentFilterChecked,
  setPaymentList,
  paymentList,
}) => {
  const allChecked = paymentFilterChecked.every(checked => checked);

  const checkAll = () => {
    // 전체 체크
    if (allChecked) {
      setPaymentFilterChecked([false, false, false, false, false]);
    } else {
      setPaymentFilterChecked([true, true, true, true, true]);
    }
    setPaymentList(['none', 'kakao', 'inicis', 'nopassbook']);
  };

  return (
    <article style={{ display: 'flex', gap: '10px', marginBottom: '5px' }}>
      <Button
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        size={'sm'}
        colorScheme="teal"
      >
        <Checkbox
          name="all"
          value={`"none", "kakao", "inicis", "nopassbook"`}
          isChecked={allChecked}
          onChange={checkAll}
        />
        <Text marginLeft={'5px'}>전체</Text>
      </Button>
      <Button
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        size={'sm'}
        colorScheme="gray"
      >
        <Checkbox
          name="none"
          value="0"
          isChecked={paymentFilterChecked[1]}
          onChange={e => {
            setPaymentFilterChecked([
              paymentFilterChecked[0],
              e.target.checked,
              paymentFilterChecked[2],
              paymentFilterChecked[3],
              paymentFilterChecked[4],
            ]);

            // 멤버십 갱신
            const updatedMembershipResult = updateCheckboxPropertyList({
              filterChecked: paymentFilterChecked,
              propertyList: paymentList,
              target: 'none',
              checked: e.target.checked, // onChange 일어난 이후의 체크상태,
              allPropertyList: ['none', 'kakao', 'inicis', 'nopassbook'],
            });

            setPaymentList(updatedMembershipResult);
          }}
        />
        <Text marginLeft={'5px'}>없음</Text>
      </Button>
      <Button
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        size={'sm'}
        colorScheme="yellow"
      >
        <Checkbox
          name="kakao"
          isChecked={paymentFilterChecked[2]}
          onChange={e => {
            setPaymentFilterChecked([
              paymentFilterChecked[0],
              paymentFilterChecked[1],
              e.target.checked,
              paymentFilterChecked[3],
              paymentFilterChecked[4],
            ]);

            // 멤버십 갱신
            const updatedMembershipResult = updateCheckboxPropertyList({
              filterChecked: paymentFilterChecked,
              propertyList: paymentList,
              target: 'kakao',
              checked: e.target.checked, // onChange 일어난 이후의 체크상태,
              allPropertyList: ['none', 'kakao', 'inicis', 'nopassbook'],
            });

            setPaymentList(updatedMembershipResult);
          }}
        />
        <Text marginLeft={'5px'}>카카오</Text>
      </Button>
      <Button
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        size={'sm'}
        colorScheme="pink"
      >
        <Checkbox
          name="inicis"
          isChecked={paymentFilterChecked[3]}
          onChange={e => {
            setPaymentFilterChecked([
              paymentFilterChecked[0],
              paymentFilterChecked[1],
              paymentFilterChecked[2],
              e.target.checked,
              paymentFilterChecked[4],
            ]);

            // 멤버십 갱신
            const updatedMembershipResult = updateCheckboxPropertyList({
              filterChecked: paymentFilterChecked,
              propertyList: paymentList,
              target: 'inicis',
              checked: e.target.checked, // onChange 일어난 이후의 체크상태,
              allPropertyList: ['none', 'kakao', 'inicis', 'nopassbook'],
            });

            setPaymentList(updatedMembershipResult);
          }}
        />
        <Text marginLeft={'5px'}>이니시스</Text>
      </Button>
      <Button
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        size={'sm'}
        colorScheme="blue"
      >
        <Checkbox
          name="nopassbook"
          isChecked={paymentFilterChecked[4]}
          onChange={e => {
            setPaymentFilterChecked([
              paymentFilterChecked[0],
              paymentFilterChecked[1],
              paymentFilterChecked[2],
              paymentFilterChecked[3],
              e.target.checked,
            ]);

            // 멤버십 갱신
            const updatedMembershipResult = updateCheckboxPropertyList({
              filterChecked: paymentFilterChecked,
              propertyList: paymentList,
              target: 'nopassbook',
              checked: e.target.checked, // onChange 일어난 이후의 체크상태,
              allPropertyList: ['none', 'kakao', 'inicis', 'nopassbook'],
            });

            setPaymentList(updatedMembershipResult);
          }}
        />
        <Text marginLeft={'5px'}>무통장</Text>
      </Button>
    </article>
  );
};

export default PaymentCheckbox;
