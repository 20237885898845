import getAuthAxios from 'utill/getAuthAxios';

const authAxios = getAuthAxios();

export const createGroup = ({ name, user_uid }) => {
  return authAxios.post(`/group`, {
    name,
    user_uid,
  });
};

export const addUserToGroup = ({ user_uid, group_uid }) => {
  return authAxios.post(`/group/add-user`, {
    user_uid,
    group_uid,
  });
};

export const getGroups = ({ page, limit }) => {
  return authAxios.get(`/group?page=${page}&limit=${limit}`);
};

export const getGroupByName = name => {
  return authAxios.get(`/group/search?name=${name}`);
};

export const getByGroupUid = group_uid => {
  return authAxios.get(`/group/${group_uid}`);
};

export const searchUserListByGroupUid = ({ group_uid, name }) => {
  return authAxios.get(`/group/${group_uid}/user?name=${name}`);
};

export const deleteGroupList = uids => {
  return authAxios.delete(`/group`, {
    data: { uids },
  });
};

export const deleteUserListFromGroup = group_uid => {
  return authAxios.delete(`/group/user?group_uid=${group_uid}`);
};
